import React from "react"
import { ArrowLeftIcon } from "@heroicons/react/24/outline"
export const QrModal = ({children, id, qr, active = false, setActive, copied}) => {
    /*
    children: social media links as html elements
    id: id of the game
    qr: the qr code as a html element
    */

    return (
        <div className="fixed top-0 left-0 bg-black flex flex-col items-center"
        style={{visibility: active ? "visible" : "hidden", width:"100vw", height:"100vh", zIndex: 150, fontSize: "25px"}}>
            <div className="w-full mb-5">
            <button onClick={() => {
                setActive(false)
                }} className="m-3 mr-auto"
                style={{alignSelf: "flex-start"}}>
            <ArrowLeftIcon className="h-8 w-8 text-white"></ArrowLeftIcon>
            </button>
            </div>
            <h2 className="my-5">Scan and join!</h2>
            {qr}
            <p className="mt-5">subsoccer.app</p>
            <p>ID: {id}</p>
            <div className="flex mt-5">
            {children}
            </div>
            {copied ? <p>Code copied to clipboard!</p> : <></>}
        </div>
    )
}
