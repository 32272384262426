import React, {useState} from 'react';

export const useInput = (initialValue: string) => {
  const [value, setValue] = useState<string>(initialValue ?? '');

  const onChange: React.ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  return {value, onChange, setValue};
};

