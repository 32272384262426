import styled from 'styled-components';

export const InputVideoUpload = styled.input`
  color: transparent;
  border: none;

  &::before {
    content: 'UPLOAD VIDEO';
    display: inline-block;
    border: 1px solid white;
    padding: 5px 8px;
    border-radius: 8px;
    margin: 0.5rem 0;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    color: #383d42;
  }
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  min-width: 200px;
  height: 200px;
`;

export const StyledButton = styled.button`
  padding: 5px 8px;
  border: 1px solid black;
  cursor: pointer;
  border-radius: 8px;
`;

export const StyledSelect = styled.select`
  padding: 5px 8px;
  outline: none;
  border-radius: 8px;
`;
export const ErrorMessage = styled.span`
  color: #F44336;
  font-size: 15px;
`;
