import React from "react";
import { Props } from "react-select";
import styled from "styled-components";
const primaryPetrol = "#225f78"
const InputWrapper = styled.div`
border-bottom: 1px solid ${primaryPetrol};
display:flex;
justify-content:flex-start;
align-items:center;
position:relative;
font-size: 18px;
font-weight: 300;
width: 100%;
height: 50px;
outline: none;
color: #383d42;
margin-bottom: 20px;`

export const Error = styled.div`
  position: absolute;
  font-weight: 400;
  bottom: -23px;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.06em;
  color: #ce482b;
`;

export const UnderLineInput = ({icon = <></>, value, name, type = "", placeholder, onChange, inputStyle = {}, error = ""}) => {

    return (
        <InputWrapper>
        {icon}
            <input className="h-full w-full absolute left-0 top-0 bg-transparent text-white" style = {inputStyle}
            onChange={onChange} placeholder={placeholder} name={name} value={value} type={type}></input>
            {!!error && <Error>{error}</Error>}
        </InputWrapper>
    )
}
