import {CommonTournamentForm} from '../../components'

export const CreateTournament = () => {

  return (
    <>
      <CommonTournamentForm/>
    </>
  )
}

