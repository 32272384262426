import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import styled from 'styled-components';

// Assets
import defaultProfileImage from '../../../assets/img/DefaultPhoto.jpg';

import {RootState} from '../../../redux';
// Services
import {servicePicture} from '../../../services/profileService';
import Snackbar, {SnackBarProps} from '../../Snackbar';
// Components
import {Button} from '../Button/Button';

// Styles
const WrapperImage = styled.div`
  display: flex;
  justify-content: start;
`;

const Img = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 1px solid white;
  object-fit: cover;
`;

const InputPhotoProfile = styled.input`
  color: transparent;
  border: none;

  &::before {
    content: 'CHANGE';
    display: inline-block;
    border: none;
    margin: 0.5rem 0;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 700;
    font-size: 12px;
    color: #383d42;
  }
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
`;

const WrapperButton = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 15px;
`;

interface File {
  btnTitle: string;
  width: string;
  height: string;
}

export const InputForFile: React.FC<File> = ({btnTitle, width, height}) => {
  const {user_id} = useSelector(({user}: RootState) => user);
  const [selectedFile, setSelectedFile] = useState<any>(
    `${process.env.REACT_APP_API_URL}/user/${user_id}/picture`,
  );
  const [displayPicture, setDisplayPicture] = useState(
    selectedFile ? selectedFile : '',
  );
  const [imageError, setImageError] = useState(false);
  const [snackbarData, setSnackbarData] = useState<{
    type: SnackBarProps['type'];
    message: SnackBarProps['message'];
    hide: SnackBarProps['hide'];
  }>({
    message: '',
    type: 'success',
    hide: true,
  });

  useEffect(() => {
    servicePicture.getPicture(user_id).catch(err => console.log(err));
  });

  useEffect(() => {
    const fetchUserInfo = async() => {
      try {
        await servicePicture.getPicture(user_id).then(() => {
          setImageError(false);
        });
      } catch (error) {
        setImageError(true);
      }
    };
    fetchUserInfo();
  });

  const handleUpload = (e) => {
    e.preventDefault();
    if (selectedFile) {
      const formData = new FormData();
      const nameOfField = 'picture';
      formData.append(
        nameOfField,
        new Blob([selectedFile], {type: selectedFile.type}),
      );
      servicePicture
        .editPicture(formData)
        .then((resp) => {
          servicePicture.getPicture(user_id);
          if (resp.status === 200) {
            setSnackbarData({
              message: 'Your picture has been updated',
              type: 'success',
              hide: false,
            });
          }
        })
        .catch((er) => {
          setSnackbarData({
            message: er.message,
            type: 'error',
            hide: false,
          });
        });
    }
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const temporaryURL = URL.createObjectURL(file);
    setDisplayPicture(temporaryURL);
  };

  const deletePhoto = (e) => {
    e.preventDefault();
    servicePicture
      .deletePicture()
      .then((resp) => {
        if (resp.status === 200) {
          setSnackbarData({
            message: 'Deletion was successful',
            type: 'success',
            hide: false,
          });
        }
        setDisplayPicture('');
      })
      .catch(() => {
        setSnackbarData({
          message: 'There was an error please try again later',
          type: 'error',
          hide: false,
        });
      });
  };

  const hideSnackBar = () => {
    setSnackbarData({message: '', type: 'success', hide: true});
  };

  return (
    <div>
      <WrapperImage>
        {imageError ? (
          <Img
            src={defaultProfileImage}
            alt="image"
            width={width}
            height={height}
          />
        ) : (
          <Img src={displayPicture} alt="image" width={width} height={height}/>
        )}
      </WrapperImage>
      <InputPhotoProfile
        accept="image/heic, image/jpeg, image/webp"
        name="profilePhoto"
        type="file"
        onChange={handlePhotoChange}
      />
      <WrapperButton>
        <Button
          style={{fontSize: '12px'}}
          onClick={(e) => handleUpload(e)}
          small
        >
          {`Save ${btnTitle}`}
        </Button>
        <Button
          style={{fontSize: '12px'}}
          onClick={(e) => deletePhoto(e)}
          small
        >
          {`Delete ${btnTitle}`}
        </Button>
      </WrapperButton>
      <Snackbar
        hide={snackbarData.hide}
        type={snackbarData.type}
        message={snackbarData.message}
        timeout={1000}
        callback={hideSnackBar}
      />
    </div>
  );
};
