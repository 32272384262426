import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Helpers
import { underAgeChecking } from '../../helpers/underAgeChecking';
// Hooks
import { useInput } from '../../hooks/useInput';
// Api
import $api from '../../http';
import { AppDispatch, RootState } from '../../redux';
import { actionUserInfo } from '../../redux/actions/user';
import { Button } from '../common/Button/Button';
// Components
import { Input, Label } from '../common/Input/Input';
import { InputForFile } from '../common/Input/InputForFile';
// Styles
import { Option, StyledSelect, } from '../CreateTournamentForm/CreateTournamentForm.styled';
import Snackbar, { SnackBarProps } from '../Snackbar';
import {
  ProfileForm, ProfileFormInputs, ProfileInner, ProfileTitle, ProfileWrapper, Title, Wrapper,
} from './styled';
import CountrySelect from '../common/CountrySelect/CountrySelect';

interface ProfileData {
  _id: string | null;
  name: string;
  email: string;
  phone?: string;
  nickname: string;
  dateOfBirth: string | Date;
  homeAddress: string;
  instagram: string;
  tiktok: string;
  profileType: string;
  underageName?: string;
  underageEmail?: string;
  underagePhone?: string;
  country: string;
}

export const EditProfile: FC = () => {
  const {
    name,
    nickname,
    phone,
    profileType,
    underagePhone,
    underageEmail,
    underageName,
    dateOfBirth,
    homeAddress,
    instagram,
    tiktok,
    country,
    user_email,
  } = useSelector(({ profileInfo }: RootState) => profileInfo);
  const { user_id } = useSelector(({ user }: RootState) => user);
  const dispatch = useDispatch<AppDispatch>();

  const [type, setType] = useState('');
  const nicknameInput = useInput(nickname || '');
  const nameInput = useInput(name || '');
  const [countryValue, setCountryValue] = useState(country || '');
  const addressInput = useInput('');
  const socialMediaInstagramInput = useInput(instagram || '');
  const socialMediaTikTokInput = useInput(tiktok || '');
  const emailInput = useInput(user_email || '');
  const phoneInput = useInput(phone || '');
  const dateInput = useInput(dateOfBirth?.slice(0, 10) || '');
  const emergencyNameInput = useInput(underageName || '');
  const emergencyEmailInput = useInput(underageEmail || '');
  const emergencyPhoneInput = useInput(underagePhone || '');
  const [snackbarData, setSnackbarData] = useState<{
    type: SnackBarProps['type'];
    message: SnackBarProps['message'];
    hide: SnackBarProps['hide'];
  }>({
    message: '',
    type: 'success',
    hide: true,
  });

  useEffect(() => {
    dispatch(actionUserInfo(user_id))
  }, [dispatch]);

  const hideSnackBar = () => {
    setSnackbarData({ message: '', type: 'success', hide: true });
  };

  const saveClickHandler = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault();
    e.stopPropagation();
    const reqData: ProfileData = {
      _id: user_id,
      name: nameInput.value || name,
      email: emailInput.value || user_email,
      nickname: nicknameInput.value || nickname,
      dateOfBirth: dateInput.value || dateOfBirth,
      homeAddress: addressInput.value || homeAddress,
      instagram: socialMediaInstagramInput.value || instagram,
      tiktok: socialMediaTikTokInput.value || tiktok,
      profileType: type ? type : profileType,
      country: countryValue || country,
    };
    if (emergencyPhoneInput.value) {
      reqData.underagePhone = emergencyPhoneInput.value;
    }
    if (emergencyEmailInput.value) {
      reqData.underageEmail = emergencyEmailInput.value;
    }
    if (emergencyNameInput.value) {
      reqData.underageName = emergencyNameInput.value;
    }
    if (phoneInput.value) {
      reqData.phone = phoneInput.value;
    }
    $api
      .patch('/user', reqData)
      .then((resp) => {
        if (resp.status === 200) {
          setSnackbarData({
            message: 'Your profile has been updated',
            type: 'success',
            hide: false,
          });
          dispatch(actionUserInfo(user_id));
        }
      })
      .catch(() => {
        setSnackbarData({
          message: 'There was an error please try again later',
          type: 'error',
          hide: false,
        });
      });
  };

  const handleChangeOptions = (e) => {
    setType(e.target.value);
  };

  const optionsForSelect = ['freestyler', 'supporter', 'organiser'];
  const formattedValue = dateOfBirth?.slice(0, 10);

  return (
    <ProfileWrapper>
      <ProfileInner>
        <ProfileTitle>Your Profile</ProfileTitle>
        <ProfileForm>
          <ProfileFormInputs>
            <div style={ { flexGrow: 1 } }>
              <Input
                labelStyle={ { color: 'white' } }
                { ...nicknameInput }
                label={ 'Nickname' }
                type={ 'text' }
                placeholder={ nickname || '' }
                value={ nicknameInput.value || '' }
              />
              <Input
                labelStyle={ { color: 'white' } }
                { ...nameInput }
                label={ 'Name' }
                type={ 'text' }
                placeholder={ name || '' }
                value={ '' || nameInput.value }
              />
              <Input
                { ...dateInput }
                labelStyle={ { color: 'white' } }
                label="Date of Birth"
                type="date"
                placeholder={ '' }
                value={ dateInput.value || formattedValue || '' }
              />
              <Input
                labelStyle={ { color: 'white' } }
                { ...addressInput }
                label="Home Address"
                type="text"
                placeholder={ '' || homeAddress }
                value={ '' || addressInput.value }
              />
              <Input
                labelStyle={ { color: 'white' } }
                { ...socialMediaInstagramInput }
                label="Instagram"
                type="text"
                placeholder={ '' || instagram }
                value={ '' || socialMediaInstagramInput.value }
              />
              <Input
                labelStyle={ { color: 'white' } }
                { ...socialMediaTikTokInput }
                label="Tik Tok"
                type="text"
                placeholder={ '' }
                value={ socialMediaTikTokInput.value || '' }
              />
              <InputForFile
                btnTitle="Photo"
                width="250px"
                height="250px"
              />
            </div>
            <div style={ { flexGrow: 1 } }>
              <Input
                labelStyle={ { color: 'white' } }
                { ...emailInput }
                label={ 'E-mail' }
                type={ 'text' }
                placeholder={ user_email || '' }
                value={ '' || emailInput.value }
              />
              <Input
                labelStyle={ { color: 'white' } }
                { ...phoneInput }
                label="Phone"
                type="text"
                placeholder={ phone || '' }
                value={ '' || phoneInput.value }
              />
              <CountrySelect setCountry={ setCountryValue } countryValue={ { value: countryValue, label: countryValue } } />
              <Wrapper>
                <Label style={ { color: 'white' } }>Select Type</Label>
                <StyledSelect
                  onChange={ handleChangeOptions }
                  name="selectedOption"
                  style={ { color: 'black', fontSize: '18px' } }
                >
                  { optionsForSelect.map((option: string) => (
                    <Option
                      key={ option }
                      value={ option }
                      style={ { color: 'black' } }
                    >
                      { option }
                    </Option>
                  )) }
                </StyledSelect>
              </Wrapper>
              { underAgeChecking(dateInput.value) ? (
                <div style={ { marginTop: '50px' } }>
                  <Title>Emergency Contacts</Title>
                  <Input
                    labelStyle={ { color: 'white' } }
                    { ...emergencyNameInput }
                    label="Contact Name"
                    type="text"
                    placeholder={ '' || underageName }
                    style={ { marginTop: '15px' } }
                    value={ emergencyNameInput.value || '' }
                  />
                  <Input
                    labelStyle={ { color: 'white' } }
                    { ...emergencyPhoneInput }
                    label="Contact Phone"
                    type="tel"
                    placeholder={ '' || underagePhone }
                    value={ emergencyPhoneInput.value || '' }
                  />
                  <Input
                    labelStyle={ { color: 'white' } }
                    { ...emergencyEmailInput }
                    label="Contact Email"
                    type="text"
                    placeholder={ '' || underageEmail }
                    value={ '' || emergencyEmailInput.value }
                  />
                </div>
              ) : null }
            </div>
          </ProfileFormInputs>
          <Button
            style={ { alignSelf: 'flex-end' } }
            onClick={ saveClickHandler }
            small
          >
            Save
          </Button>
        </ProfileForm>
        <Snackbar
          hide={ snackbarData.hide }
          type={ snackbarData.type }
          message={ snackbarData.message }
          timeout={ 1000 }
          callback={ hideSnackBar }
        />
      </ProfileInner>
    </ProfileWrapper>
  );
};
