import React from "react";
import styled from "styled-components";
const primaryPetrol = "#225f78"

const StyledWrapper = styled.div`
border: 1px solid ${primaryPetrol};
border-radius: 50px;
display:flex;
flex-wrap: nowrap;
align-items:center;
padding-left: 10px;
padding-right: 10px;
position:relative;
font-size: 18px;
font-weight: 300;
height: 50px;
outline: none;
color: #383d42;
margin-bottom: 0;
width:fit-content;
`

export const SelectionWrapper = ({children, title = <></>, style = {}}) => {
    return (
        <div style = {style}>
        {title}
        <StyledWrapper>
        {children}
        </StyledWrapper>
        </div>
    )
}
