import { createAsyncThunk } from '@reduxjs/toolkit';

import { getTournamentsById, updateTournamentUsers } from '../../services/tournament';

export const tableTournamentActionFetch = createAsyncThunk(
  'table/tableTournamentActionFetch',
  async(id: string, { rejectWithValue }) => {
    try {
      const data = await getTournamentsById(id);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const tableTournamentActionFetchSecond = createAsyncThunk(
  'table/tableTournamentActionFetchSecon',
  async(id: string, { rejectWithValue }) => {
    try {
      const data = await getTournamentsById(id);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const updatePlayersAsync = createAsyncThunk(
  'table/updatePlayers',
  async ({ tournamentId, players }:{tournamentId: string, players: any[]}, { rejectWithValue }) => {
    try {
      const response = await updateTournamentUsers(tournamentId, players);      
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
