import React, {CSSProperties, FC} from 'react';
import styled from 'styled-components';
const primaryPetrol = "#225f78"

const ButtonStyled = styled.button`
  width: 100%;
  height: 7vh;
  position:relative;
  border-radius: 50px;
  display:flex;
  align-items: center;
  color: white;
  border: none;

  font-family: resolve;
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  align-self: center;
  letter-spacing: 0.006em;

  cursor: pointer;

  @media only screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.05em;
    width: 100%;
    height: 57px;
  }
  transition: all 200ms ease-in-out;
  &:hover {
    background-color: ${({primary}: ButtonProps) =>
  primary ? '#225F78' : '#CE482B '};
    transform: scale(0.95);
  }
`;
interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  large?: boolean;
  small?: boolean;
  medium?: boolean;
  primary?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  icon?: HTMLElement;
  text?:string;
}

export const RoundedButton: FC<ButtonProps> = ({children, style, text, ...props}) => {
  return (
    <ButtonStyled style={style} {...props}>
        <div className='absolute left-0 top-0 h-full w-full flex justify-center items-center'>
            <p>{text}</p>
        </div>
      {children}
    </ButtonStyled>
  );
};

// import { FC, useEffect, useMemo, useState } from 'react';
// import Select from 'react-select'

// export const RoundedButton = ({icon, text, onClick, style}) => {
//     return (
//         <button className='p-4 btn flex flex-row content-around items-center rounded-full block w-full p-2.5 text-3xl' style={style} onClick={onClick}>
//             {icon} <p style={{margin: "0px auto"}}>{text}</p>
//         </ button>
//     )
// }
