import React from "react"
import { RoundedButton } from "../"
import { useNavigate } from "react-router-dom"
import { TrophyIcon } from "@heroicons/react/24/outline"
import { lightPetrol } from "../../assets/colors"
import { Margin } from "styled-icons/material"
export const PlayAgain = ({winner}) => {
    const navigate = useNavigate()
    const handlePlayAgain = () => {
        navigate(`/create`)
    }
    return <div className="flex flex-col items-center absolute w-full bottom-10 left-0 p-5">
        <TrophyIcon className="h-10 w-10" style={{color: lightPetrol}}></TrophyIcon>
        <h2>Tournament winner:</h2>
        <h2 style={{fontSize: "35px", margin:"10px"}}>{winner}</h2>
        <RoundedButton text="Play again" style={{backgroundColor: "red", margin: "20px"}} onClick={handlePlayAgain}>
            <></>
        </RoundedButton>
    </div>
}
