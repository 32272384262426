import { FormikErrors } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import Select from 'react-select'
import countryList from 'react-select-country-list/country-list'

const customSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    color: '#000',
    marginBottom: '40px'
  }),
};

interface ICountry {
  value: string,
  label: string
}

interface ICountrySelect {
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<any>>,
  setCountry?: (value: string) => void,
  countryValue?: { value: string, label: string }
}

const CountrySelect: FC<ICountrySelect> = ({ setFieldValue, setCountry, countryValue }) => {
  const options = useMemo(() => countryList().getLabels().map((country) => ({ value: country, label: country })), [])

  return (
    <Select
      options={ options }
      name='country'
      onChange={ (country) => {
        if (country?.value) {
          if (setFieldValue) {
            setFieldValue('country', country.value)
          } else if (setCountry) {
            setCountry(country.value)
          }
        }
      } }
      defaultValue={ countryValue }
      placeholder={ 'Select your country' }
      isClearable
      isSearchable
      required
      styles={ customSelectStyles }
    />
  )
}

export default CountrySelect
