import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ArrowLeftIcon, ArrowLeftStartOnRectangleIcon, PencilSquareIcon, CameraIcon} from '@heroicons/react/24/outline';
import { UserCircleIcon } from '@heroicons/react/24/solid';
import ReactCountryFlag from 'react-country-flag';
import { useNavigate } from 'react-router-dom';
import { actionUserLogout } from '../../redux/actions/user';

// Assets
import { lightPetrol } from '../../assets/colors';
import defaultProfileImage from '../../assets/img/DefaultPhoto.jpg';
import countryCodes from '../../assets/countrycodes';
import Instagram from '../../assets/svg/instagram.svg';
import TikTok from '../../assets/svg/tiktok.svg';
import { RootState } from '../../redux';
import { AppDispatch } from '../../redux';
import { actionUserInfo } from '../../redux/actions/user';
// Services
import { servicePicture } from '../../services/profileService';
// Styles
import {
  CardWrapper,
  Container,
  Description,
  Img,
  TextCard,
  Title,
} from './styled';

export const Card = ({showButtons}) => {
  const [imageError, setImageError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const filePickerRef = useRef<HTMLInputElement>(null)
  let { id } = useParams();
  if (!id) {
    const { user_id } = useSelector(({ user }: RootState) => user);
    id = user_id!
  }

  const [selectedFile, setSelectedFile] = useState<any>(
    `${process.env.REACT_APP_API_URL}/user/${id}/picture`,
  );

  const [displayPicture, setDisplayPicture] = useState(
    selectedFile ? selectedFile : '',
  );

  useEffect(() => {
    dispatch(actionUserInfo(id || null));
  }, [dispatch]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        await servicePicture.getPicture(id).then(() => {
          setImageError(false);
        });
      } catch (error) {
        setImageError(true);
      }
    };
    fetchUserInfo();
  });

  const navigate = useNavigate();
  const buttonLogoutHandlerClick = () => {
    dispatch(actionUserLogout()).then(() => window.location.reload());
  };

  const {
    name,
    instagram,
    tiktok,
    country,
    eloRank,
    nickname,
    playedGames,
    trophies,
    wins,
  } = useSelector(({ profileInfo }: RootState) => profileInfo);

  const profileImageUrl = `${process.env.REACT_APP_API_URL}/user/${id}/picture`;

  const handleUpload = (file) => {
    if (selectedFile) {
      const formData = new FormData();
      const nameOfField = 'picture';
      formData.append(
        nameOfField,
        new Blob([file], {type: file.type}),
      );
      servicePicture
        .editPicture(formData)
        .then((resp) => {
          servicePicture.getPicture(id);
        })
    }
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    const temporaryURL = URL.createObjectURL(file);
    setDisplayPicture(temporaryURL);
    handleUpload(file)
  };

  const activateImagePicker = () => {
    filePickerRef.current?.click()
  }

  return (

    <div className='flex flex-col items-center justify-center mb-auto'>

      <div className='flex items-center justify-between w-full mb-5'>
        <button onClick={() => {navigate(-1)}}> 
          <ArrowLeftIcon className='h-8 w-8 text-white' style={{color: lightPetrol}}/>
        </button>

        {showButtons ? (
        <button className='flex' onClick={buttonLogoutHandlerClick}>
          <ArrowLeftStartOnRectangleIcon className='h-6 w-6 mr-3' style={{color: lightPetrol, transform: "rotate(180deg)"}}></ArrowLeftStartOnRectangleIcon>
        <p style={{fontSize: "20px", color: lightPetrol}}>Sign out</p>
      </button>) : <></>}
      </div>

      <div className='mb-5 w-full relative'>
        {showButtons ? (
        <button onClick={() => navigate("/profile/edit")}>
        <PencilSquareIcon className='h-6 w-6 absolute top-3 right-3' style={{color: lightPetrol}}></PencilSquareIcon>
        </button>
        ) : <></>}

        <div className='mx-auto relative' style={{width: "200px", height: "200px" }}>
          <input
          accept="image/heic, image/jpeg, image/webp"
          name="profilePhoto"
          type="file"
          ref={filePickerRef}
          onChange={handlePhotoChange}
          hidden></input>
          {showButtons ? (
          <button 
          className='bg-white flex items-center absolute justify-center w-7 h-7' 
          style={{borderRadius: "100%", bottom:"23px", right:"23px"}}
          onClick={activateImagePicker}>
            <CameraIcon className='h-5 w-5 absolute' style={{color: lightPetrol}}></CameraIcon>
          </button>
          ) : <></>}
          {imageError ? (
            <UserCircleIcon className='w-full m-0 p-0' style={{borderRadius: "100%", color:lightPetrol}}  />
          ) : (
            <div style={
              {backgroundImage: `url(${profileImageUrl})`, 
              width: "100%", 
              height: "100%", 
              borderRadius: "100%", 
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}></div>

          )}
        </div>
      </div>
      <p className='text-center' style={{fontSize: "25px"}}>{name}</p>
      <h2 style={{fontSize: "35px"}}>{nickname}</h2>
      <div className='flex items-center justify-center' style={{fontSize: "25px"}}>
        <ReactCountryFlag countryCode={countryCodes[country]}/>
        <p className='ml-3'>{country}</p>
      </div>
      <div className='flex justify-around items-center w-full mt-10'>
        <div className='flex flex-col items-center justify-center' style={{fontSize: "25px"}}>{wins}<p className='text-center'>Wins</p></div>
        <div className='flex flex-col items-center justify-center' style={{fontSize: "25px"}}>{playedGames - wins}<p>Losses</p></div>
        <div className='flex flex-col items-center justify-center' style={{fontSize: "25px"}}>{playedGames}<p>Total</p></div>
      </div>
    </div>
    // <CardWrapper>
    //   
    //   <Title>{name || ''}</Title>
    //   <Description>
    //     <TextCard>{country ? `Country: ${country}` : null}</TextCard>{' '}
    //     <div style={{ display: 'flex', gap: '30px' }}>
    //       <Container>
    //         <TextCard>{`ELO Rating: ${eloRank}`}</TextCard>
    //         <TextCard>{`Played games: ${playedGames}`}</TextCard>
    //       </Container>
    //       <Container>
    //         <TextCard>{`Wins: ${wins}`}</TextCard>
    //         <TextCard>{`Trophies: ${trophies}`}</TextCard>
    //       </Container>
    //     </div>
    //     <div>
    //       {instagram ? (
    //         <a href={instagram} target="_blank" rel="noreferrer">
    //           <img src={Instagram} alt="Instagram Icon" />
    //         </a>
    //       ) : null}
    //       {tiktok ? (
    //         <a href={tiktok} target="_blank" rel="noreferrer">
    //           <img src={TikTok} alt="TikTok Icon" />
    //         </a>
    //       ) : null}
    //     </div>
    //   </Description>
    // </CardWrapper>
  );
};
function getPicture() {
  throw new Error('Function not implemented.');
}
