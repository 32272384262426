import {useCallback, useEffect, useState} from 'react';
import {NavLink, useNavigate} from 'react-router-dom';
import { ShareIcon, QuestionMarkCircleIcon, TrophyIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import { MenuButton } from './MenuButton';
import {
BurgerMenu, BurgerMenuIcon, BurgerTitle, BurgerWrapper, DropdownContainer, DropdownList,
} from './Burger.styled';
import { actionUserLogout } from '../../redux/actions/user';
import { lightPetrol } from '../../assets/colors';
const menuButtonFontSize = "50px"
export const Burger = () => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [openProfile, setOpenProfile] = useState<boolean>(false);
  const {user_id, roles} = useAppSelector(({user}) => user);  
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const buttonLogoutHandlerClick = () => {
    dispatch(actionUserLogout()).then(() => navigate('/tournament'));
  };
  const handleCloseMenuPress = useCallback(() => {
    setOpenMenu(false);
  }, []);
  useEffect(() => {
    window.document.addEventListener('click', handleCloseMenuPress);
    return () => {
      window.document.removeEventListener('click', handleCloseMenuPress);
    };
  }, [handleCloseMenuPress]);

  if (!user_id) {
    return (
      <BurgerWrapper onClick={(e) => e.stopPropagation()}>
        <NavLink style={{fontSize: 30}} to="/login">
          Login
        </NavLink>
      </BurgerWrapper>
    );
  }
  return (
    <BurgerWrapper onClick={(e) => e.stopPropagation()}>
      <BurgerMenuIcon
        onClick={() => setOpenMenu((prev) => !prev)}
        open={openMenu}
      ></BurgerMenuIcon>
      <BurgerMenu className={`${openMenu ? 'active' : ''}`}>
        <div>
        <li onClick={() => setOpenMenu(false)}>
          <NavLink to="/tournament"><MenuButton
          icon={<ShareIcon className='h-8 w-8' style={{color: lightPetrol}}></ShareIcon>}
          text="TOURNAMENT"/></NavLink>
        </li>
        <li onClick={() => setOpenMenu(false)}>
          <NavLink  to="/rules"><MenuButton
          icon={<QuestionMarkCircleIcon className='h-8 w-8' style={{color: lightPetrol}}></QuestionMarkCircleIcon>}
          text="HOW TO PLAY"/></NavLink>
        </li>
        <li onClick={() => setOpenMenu(false)}>
          <NavLink  to="/create"><MenuButton
          icon={<TrophyIcon className='h-8 w-8' style={{color: lightPetrol}}></TrophyIcon>}
          text="RANKINGS"/></NavLink>
        </li>
        <li onClick={() => setOpenMenu(false)}>
          <NavLink  to="/profile/edit"><MenuButton
          icon={<Cog6ToothIcon className='h-8 w-8' style={{color: lightPetrol}}></Cog6ToothIcon>}
          text="SETTINGS"/></NavLink>
        </li>
        {/* { (roles && roles?.[0] != "Guest")
          &&
          <DropdownContainer onClick={() => setOpenProfile(prev => !prev)}>
            <p>Profile</p>
            <DropdownList open={openProfile}>
              <li onClick={() => setOpenMenu(false)}>
                <NavLink to={`/player-card/${user_id}`}>Player Card</NavLink>
              </li>
              <li onClick={() => setOpenMenu(false)}>
                <NavLink to="/profile">Profile</NavLink>
              </li>
            </DropdownList>
          </DropdownContainer>
        }
        <li onClick={buttonLogoutHandlerClick}>Logout</li> */}
        </div>
      </BurgerMenu>
    </BurgerWrapper>
  );
};
