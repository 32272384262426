import {createSlice, PayloadAction} from '@reduxjs/toolkit';

import {actionUserInfo} from '../actions/user';

interface userState {
  user_id: string | null;
  user_email: string;
  name: string;
  nickname: string;
  phone: string;
  isLoading: boolean;
  error: unknown;
  sharingId: string | null;
  playedGames: number;
  wins: number;
  trophies: number;
  eloRank: number;
  dateOfBirth: string;
  country: string;
  homeAddress: string;
  instagram: string;
  tiktok: string;
  underageName: string;
  underageEmail: string;
  underagePhone: string;
  profileType: string;
}

const initialState: userState = {
  user_id: null,
  user_email: '',
  name: '',
  nickname: '',
  phone: '',
  isLoading: false,
  error: '',
  sharingId: null,
  playedGames: 0,
  wins: 0,
  trophies: 0,
  eloRank: 0,
  dateOfBirth: '',
  country: '',
  homeAddress: '',
  instagram: '',
  tiktok: '',
  underageName: '',
  underageEmail: '',
  underagePhone: '',
  profileType: '',
};

const profileInfoSlice = createSlice({
  name: 'profileInfo',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionUserInfo.pending, (state) => {
      state.isLoading = true;
    }),
      builder.addCase(actionUserInfo.fulfilled, (state, {payload}) => {
        state.isLoading = false;
        state.user_id = payload._id;
        state.user_email = payload.email;
        state.name = payload.name;
        state.nickname = payload.nickname;
        state.phone = payload.phone;
        state.error = payload.error;
        state.sharingId = payload.sharingId;
        state.playedGames = payload.playedGames;
        state.wins = payload.wins;
        state.trophies = payload.trophies;
        state.eloRank = payload.eloRank;
        state.dateOfBirth = payload.dateOfBirth;
        state.country = payload.country;
        state.homeAddress = payload.homeAddress;
        state.instagram = payload.instagram;
        state.tiktok = payload.tiktok;
        state.underageName = payload.underageName;
        state.underageEmail = payload.underageEmail;
        state.underagePhone = payload.underagePhone;
        state.profileType = payload.profileType;
      }),
      builder.addCase(
        actionUserInfo.rejected,
        (state, {payload}: PayloadAction<unknown>) => {
          state.isLoading = false;
          state.error = payload;
        },
      );
  },
});

export default profileInfoSlice.reducer;
