import styled from 'styled-components';

import shareSvg from '../../assets/svg/share.svg';

export const TableTournamentMobileHeader = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  position: absolute;
  right: 0;
  left: 0;
  padding: 10px 20px;
  top: 120px;

  li {
    font-size: 24px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: #fff;

    &.active {
      color: #fff;
      padding-bottom: 5px;
      border-bottom: 2px solid #d30028;
    }
  }
`;
export const TableTournamentMobilContent = styled.div`
  padding-top: 10px;
  height: 96%;
  width: 290px;
`;
export const TableTournamentMobilName = styled.p<{ color?: string }>`
  font-size: 18px;
  line-height: 26px;
  color: ${({color}) => color || '#fff'};
  font-family: Resolve-BoldNrw;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.108px;
  width: 100%;
`;
export const TableTournamentMobilPlace = styled.p<{ color?: string }>`
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.006em;
  font-weight: 100;
  color: ${({color}) => color || '#fff'};
`;
export const TableTournamentMobilDate = styled.p<{ color?: string }>`
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.006em;
  font-weight: 100;
  margin-bottom: 25px;
  color: ${({color}) => color || '#fff'};
`;

export const TableTournamentMobilePair = styled.div`
  margin: 14px 0;
`;
export const TabsMobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 17px;
`;

export const LinkMobile = styled.div`
  color: white;
  background-color: inherit;
  border: 0px;
  font-family: Resolve-RegularCn;

  &.active {
    border-bottom: 2px solid #ffbc11;
    padding-bottom: 7px;
  }
`;
export const Bottom = styled.div<{ showShare?: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: ${(props) => (props.showShare ? 'space-between' : 'end')};
  align-items: center;
  height: 52px;
  padding: 0 27px;
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.006em;
  font-weight: bold;
  color: #ffffff;
  z-index: 1000;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    height: 100%;
    transform: rotate(180deg);
    background: linear-gradient(90.67deg, #cc2b24 22.54%, #ffbc11 158.72%);
  }
`;
export const BottomShare = styled.div`
  text-transform: uppercase;
  display: flex;
  align-items: center;

  &::before {
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    background-image: url(${shareSvg});
    background-repeat: no-repeat;
    margin-right: 10px;
  }
`;

export const MobileQRButton = styled.button`
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid white;
  cursor: pointer;
`;

export const WrapperTournament = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const WrapperForTitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: end;
`;
