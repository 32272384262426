import React, { useRef } from 'react';
// Components
import './DatePicker.css';
import {ChevronDownIcon, CalendarIcon, ClockIcon, ArrowRightIcon} from '@heroicons/react/24/outline';
// Styles
import { Title } from '../../style/Custom';
import { Details } from '../Details/Details';
import {
  Box,
  BoxItem,
  Input,
  NextButton,
  Button,
  InputBox,
  Tournament,
} from './CreateTournamentForm.styled';
import { UnderLineInput, SelectionWrapper, DatePicker, TimePicker, TournamentProgressBar } from '../../components';

import { Rules } from './Rules';
import SelectForSearch from './SelectSearch';
const primaryPetrol = "#225f78"

interface ClassicTournament {
  checkType: string;
  handleSubmit: () => void;
  handleChangeValue: (e) => void;
  formData: any;
  handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleTimeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  handleDateChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options: {
    label: string;
    value: string;
  }[];
  searchValue: string;
}

export const ClassicTournamentForm: React.FC<ClassicTournament> = ({
  handleInputChange,
  handleSelectChange,
  checkType,
  formData,
  handleSubmit,
  handleChangeValue,
  handleTimeChange,
  handleDateChange,
  options,
  searchValue,
}) => {

  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const datePickerRef = useRef<HTMLInputElement>()
  const timePickerRef = useRef<HTMLInputElement>()

  const activateDatePicker = () => {
    datePickerRef.current!.focus()
    datePickerRef.current!.showPicker()
  }

  const activateTimePicker = () => {
    timePickerRef.current!.focus()
    timePickerRef.current!.showPicker()
  }
  const currentDate = new Date(formData.date)
  const dateTitle = <div className='flex'><CalendarIcon style={{"color": primaryPetrol}} className="h-5 w-5"></CalendarIcon><h2 className='ml-3 mb-3'>Date</h2></div>
  const timeTitle = <div className='flex'><ClockIcon style={{"color": primaryPetrol}} className="h-5 w-5"></ClockIcon><h2 className='ml-3 mb-3'>Time</h2></div>
  return (
    <form style={{ width: '100%' }} action = "" onSubmit={handleSubmit}>
      <Tournament>
      <TournamentProgressBar progress='Tournament'></TournamentProgressBar>
        <Box>
          <BoxItem>
            <Title>New tournament</Title>
              <InputBox>
              <UnderLineInput value = {formData.name} name="name" onChange={handleChangeValue} placeholder="Name"></UnderLineInput>
              </InputBox>
              <Box style={{display: "flex", marginTop: "20px", marginBottom: "20px"}}>
              <InputBox>
                <SelectionWrapper title={dateTitle}>
                  <DatePicker onChange = {handleDateChange} value={formData.date.split("T")[0]} reference={datePickerRef}></DatePicker>
                  <ChevronDownIcon className='h-5 w-5 text-white' onClick={activateDatePicker}></ChevronDownIcon>
                </SelectionWrapper>
              </InputBox>
              <InputBox>
                <SelectionWrapper title={timeTitle}>
                  <TimePicker onChange={handleTimeChange} value={formData.date.split("T")[1]} reference={timePickerRef}></TimePicker>
                  <ChevronDownIcon className='h-5 w-5 text-white' onClick={activateTimePicker}></ChevronDownIcon>
                </SelectionWrapper>
              </InputBox>
              <input type="hidden" name="date" value={formData.date} required min={new Date().toLocaleDateString('en-CA')}></input>
              </Box>
            {/* <InputBox>
              <label>Date&Time</label>
              <Input
                name="date"
                type="datetime-local"
                onChange={handleChangeValue}
                value={formData.date}
                required
                min={new Date().toLocaleDateString('en-CA')}
              />
            </InputBox> */}
            <InputBox>
            <div className='flex'><CalendarIcon style={{"color": primaryPetrol}} className="h-5 w-5"></CalendarIcon><h2 className='ml-3'>Location</h2></div>
              <SelectForSearch
                handleInputChange={handleInputChange}
                options={options}
                handleChange={handleSelectChange}
                searchValue={searchValue || options[0]?.label}
                label=""
                placeholder="Enter a location..."
              />
            </InputBox>
          </BoxItem>
          {isMobile ? (
            <></>
          ) : (
            <Rules active="classic" checkType={checkType} />
          )}
        </Box>
      </Tournament>
    </form>
  );
};
