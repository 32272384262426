import React, {FC, useState} from 'react'
import {useNavigate} from 'react-router'
import styled from 'styled-components'

import {useAppSelector} from '../../hooks/redux'
import {useInput} from '../../hooks/useInput'
import $api from '../../http'
import {Flex} from '../../style/Custom'
import {Button} from '../common/Button/Button'
import {Input} from '../common/Input/Input'
import { InputWithButton } from '../common/inputWithButton/inputWithButton'
import { ArrowRightIcon } from '@heroicons/react/24/outline'

const LoginStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    justify-content: center;
    width: 100%;
  }
`

const Box = styled.div`
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    justify-content: flex-start;
    width: 100%;
    h2 {
      margin-bottom: 30px;
    }
  }
`

const Title = styled.h2`
  font-family: resolve;
  font-weight: 700;
  font-size: 24px;
  text-align:center;
  letter-spacing: 0.016em;
  color: white;
  margin-bottom: 26px;
`

const Text = styled.p`
  color: white;
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 17px;
  text-align: center;
  letter-spacing: 0.06em;
  margin: 20px 0;
`
export const ForgotPassword: FC = () => {
  const email = useInput('')
  const navigate = useNavigate()
  const [error, setError] = useState("")
  const {isLoading} = useAppSelector(({user}) => user)
  const buttonSendEmailHandlerClick = async(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.preventDefault()
    // send email request
    if (email.value) {
      await $api
        .post('/user/forgot-password', {email: email.value,})
        .then(() => {
          navigate('/login')
        })
        .catch(err => {
          if (err.response.data.message[0].length === 1) {
            return setError(err.response.data.message!)
          }
          else {
            setError(err.response.data.message[0])
          }
        })

    }
  }

  if (isLoading) {
    return <h1>Loading...</h1>
  }

  return (
    <LoginStyled>
      <Box>
        <Title>Forgot Password?</Title>
        <Text>
          Enter your email address and we&apos;ll send you a link to reset your
          password.
        </Text>
        <InputWithButton 
        value={email.value}
        onChange={email.onChange}
        onClick={buttonSendEmailHandlerClick}
        placeholder='Email address'
        type="email"
        icon={<ArrowRightIcon className='h-8 w-8 text-black'></ArrowRightIcon>}
        errorString={error}
         />
        <Flex flex_direction={'column'}>
        </Flex>
      </Box>
    </LoginStyled>
  )
}
