import React from "react";
import { useState, useEffect } from "react";
import { TournamentInfo } from "./TournamentInfo";
import { QuestionMarkCircle } from "styled-icons/heroicons-outline";
import { lightPetrol } from "../../assets/colors";
import { GuestAddingModal } from "../Modal/GuestAddingModal";

export const QuestClassicPlayers = ({tournament, open, setOpenModal, applyGuestHandlerClick}) => {
    return (
        <div>
            <TournamentInfo tournament={tournament}></TournamentInfo>
            {open && <GuestAddingModal
            setOpen={setOpenModal}
            applyGuestHandlerClick={applyGuestHandlerClick}
            type="Classic"
        />}
            <div className="flex flex-col justify-center items-center" style={{marginTop: "200px"}}>
                <QuestionMarkCircle className="w-10 h-10 mb-3" style={{color: lightPetrol}}></QuestionMarkCircle>
                <p>Waiting for the host to start the tournament</p>
            </div>
        </div>
    )
}
