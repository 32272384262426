import React from 'react'
import { PlusIcon, MinusIcon } from "@heroicons/react/24/solid"

export const QuickplayPointsCounter = ({points, setPoints, player, ...props}) => {
    return (
        <div className="mx-auto w-full flex flex-col justify-center items-center relative box-border" {...props}>
        <h2 className="text-center" style={{fontSize: "30px"}}>{player}</h2>
        <div className="w-full flex justify-around items-center">
            <div className='relative z-10' onClick={() => setPoints(Math.max(points - 1, 0))}><MinusIcon className="h-8 w-8 ml-2"></MinusIcon></div>
            <h1 style={{fontSize: "100px"}}>{points}</h1>
            <PlusIcon className="h-8 w-8 ml-2"></PlusIcon>
        </div>
        <div className='w-full h-full absolute left-0 top-0 z-0' 
        onClick = {() => {setPoints(points + 1)}}></div>
        </div>
    )
}
