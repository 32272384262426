// Components
// Styles
import {
  DivName, DivScore, Inner, InputBox, InputName, InputScore
} from "../Player/styled";
import usePointHandler from "./usePointHanlder";

const ScoreCounter = ({ opponent, groupData, cellId }) => {
  const { PointHandler, handlePoints } = usePointHandler(cellId, false);
  
  return (
    <Inner>
      {!opponent.disabled ? <PointHandler /> : null}
      <InputBox>
        <DivName
          hoverScroll={
            opponent.name.length > 12 && opponent.name !== '(No opponent)'
              ? true
              : false
          }
          finishWin={groupData.finishMatch}
          win={opponent.win}
        >
          <InputName
            disabled={true}
            type="text"
            value={opponent.name.toLocaleUpperCase() || opponent.id}
          />
        </DivName>
        <DivScore finishWin={groupData.finishMatch}>
          <div>
            <InputScore
              className="value"
            >
              {opponent.score}
            </InputScore>
          </div>
        </DivScore>
      </InputBox>
    </Inner>
  );
};

export default ScoreCounter;
