import styled from 'styled-components';
import { lightPetrol, primaryPetrol } from '../../assets/colors';

import starSvg from '../../assets/svg/star.svg';
import winSvg from '../../assets/svg/win.svg';

type LineProps = {
  width?: number;
  height: number;
  margin: number;
  marginPair: number;
  marginRightRound: number;
  lineHeight: number;
  color?: string;
  win?: boolean;
};
type DivNameProps = {
  win?: boolean;
  finishWin?: boolean;
  hoverScroll: boolean;
};
type GroupTeamProps = {
  isAfterArrow: boolean;
  isBeforeArrow: boolean;
  stage: number;
  lengthObject: {
    [key: string]: number;
  };
};
type StageBlockProps = {
  stage: number;
};
type ConnectingLineProps = {
  index: number;
  stage: number;
};
export const LineTop = styled.div<LineProps>`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: ${({marginRightRound}) => marginRightRound / 2}px;
  height: ${({lineHeight}) => lineHeight}px;
  background-color: ${({color}) => color};
  right: -${({marginRightRound}) => marginRightRound / 2}px;
  top: -${({margin, lineHeight}) => margin / 2 + lineHeight / 2}px;

  ​ &::before {
    content: '';
    display: block;
    height: ${({marginPair, height, margin}) =>
      marginPair / 2 + height + margin}px;
    transform: translateY(-${({marginPair, height, margin}) => marginPair / 2 + height + margin}px);
    width: ${({lineHeight}) => lineHeight}px;
    background-color: ${({color}) => color};
  }
`;
export const LineBottom = styled.div<LineProps>`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: ${({marginRightRound}) => marginRightRound / 2}px;
  height: ${({lineHeight}) => lineHeight}px;
  background-color: ${({color}) => color};
  right: -${({marginRightRound}) => marginRightRound / 2}px;
  top: ${({height, lineHeight, margin}) =>
    height + margin / 2 - lineHeight / 2}px;

  ​ &::before {
    content: '';
    display: block;
    height: ${({marginPair, height, margin}) =>
      marginPair! / 2 + height + margin}px;
    width: ${({lineHeight}) => lineHeight}px;
    background-color: ${({color}) => color};
  }

  &::after {
    content: '';
    display: block;
    position: absolute;
    top: ${({marginPair, height, margin}) =>
      height + margin + marginPair / 2}px;
    right: -${({marginRightRound}) => marginRightRound / 2}px;
    width: ${({marginRightRound}) => marginRightRound / 2}px;
    height: ${({lineHeight}) => lineHeight}px;
    background-color: ${({color}) => color};
  }
`;
export const Inner = styled.div`
  display: flex;
  position: relative;

  .value {
    position: relative;
    border: none;
    font-weight: 700;
    font-size: 17px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &::disabled {
      background-color: white;
    }
  }
`;
export const InputBox = styled.div`
  display: flex;
`;
export const DivScore = styled.div<{
  finishWin: boolean;
}>`
  width: 49px;
  height: 32px;
  overflow: hidden;
  display: flex;
  background-color: transparent;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    flex: 1;

    &::after {
      content: '';
      display: block;
      width: 2px;
      height: 16px;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 10px;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }
  }
`;
export const DivName = styled.div<DivNameProps>`
  height: 32px;
  overflow: hidden;
  background-color: transparent;
  display: flex;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  display: flex;
  align-items: center;
  position: relative;
  ${({win, finishWin}) =>
    win &&
    `
​
input[type="text"] {
    background: ${
      finishWin
        ? 'linear-gradient(102.2deg, #EDEDE8 -8.27%, rgba(255, 188, 17, 0) 83.13%), #CC2B24;'
        : '#EDEDE8'
    };
}
`}
`;
export const InputName = styled.input`
color: white;
max-width: 80px;
  background-color: transparent;
  padding: 15px;

  ​ &::placeholder {
  }
`;
export const InputScore = styled.div`
  width: 100%;
  background: transparent;
  color: ${lightPetrol};
  text-align: center;
  position: relative;
  cursor: pointer;
`;
export const PlayerWrapper = styled.div`
  display: flex;
`;
export const GroupTeam = styled.div<GroupTeamProps>`
  border: 2px solid ${primaryPetrol};
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  margin: ${({lengthObject, stage}) => {
    switch (stage) {
      case 1:
        return '18px 0';
      case 2:
        return lengthObject.column1 >= 3 ? '72px 0' : '20px 0';
      case 3:
        return lengthObject.column2 >= 3 ? '72px 0' : '0';
      default:
        return '0px';
    }
  }};
  gap: 5px;
  position: relative;
  ${({isAfterArrow}) =>
    isAfterArrow &&
    `
    &::after {
      content: '';
      position: absolute;
      width: 35px;
      height: 2px;
      top: 50%;
      right: 0;
      transform: translate(100%, -50%);
      background-color: ${primaryPetrol}
    }
`}
  ${({isBeforeArrow}) =>
    isBeforeArrow &&
    `
    &::before {
      content: '';
      position: absolute;
      width: 35px;
      height: 2px;
      top: 50%;
      left: 0;
      transform: translate(-100%, -50%);
      background-color: ${primaryPetrol};
    }
`}
`;

export const StageBlock = styled.div<StageBlockProps>`
  display: flex;
  flex-direction: column;
  height:100%;
  align-self:start;
  justify-content:space-around;
  ${({stage}) =>
    stage === 1 &&
    `
    align-self: start;
`
}
`;
export const ConnectingLine = styled.div<ConnectingLineProps>`
  position: absolute;
  background: ${primaryPetrol};
  width: 2px;
  height: ${({stage}) => (stage === 1 ? '58px' : '107px')};
  right: -36px;
  top: 50%;
  transform: ${({index}) =>
    index % 2 === 0 ? 'translate(0, 0)' : 'translate(0, -100%)'};
`;

export const PlayerMobileWrapper = styled.div<{
  win: boolean;
  finishWin: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 33px;
  background-color: white;
  margin-bottom: 3px;
  font-size: 20px;
  line-height: 21px;
  letter-spacing: 0.006em;
  font-weight: bold;
  border-radius: 4px;
  z-index: 100;
`;

export const PlayerMobileName = styled.div<{
  win: boolean;
  finishWin: boolean;
}>`
  height: 100%;
  background-color: white;
  width: 70%;
  padding: 0 0 0 11px;
  border-radius: 4px;
  font-size: 14px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: black;

  &::after {
    content: '';
    display: ${({win, finishWin}) =>
      win ? (finishWin ? 'block' : 'none') : 'none'};
    width: 14px;
    height: 14px;
    background-image: url(${({win}) => (win ? starSvg : '')});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 11px;
  }
`;

export const PlayerMobileScoreBox = styled.div`
  display: flex;
  align-items: center;
  width: 40%;
  height: 100%;
`;

export const PlayerMobileScore = styled.input<{
  finishWin: boolean;
  win: boolean;
}>`
  width: 100%;
  height: 100%;
  background-color: transparent;
  outline: none;
  border: none;
  color: white;
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  border-right: 1px solid white;
  border-radius: 0;
  user-select: none;
  pointer-events: none;
  color: ${({finishWin}) => finishWin ? 'white' : 'black'};

  &:last-child {
    border-right: none;
  }

  &:disabled,
  &::placeholder {
    color: black;
    opacity: 0.7;
  }

  &[type='number'] {
    font-family: resolve !important;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 21px;
    letter-spacing: 0.006em;
  }
`;
