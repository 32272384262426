import styled from 'styled-components';

import {Label} from '../common/Input/Input';

export const ProfileWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ProfileInner = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  @media only screen and (max-width: 760px) {
    width: 100%;
  }

  @media only screen and (min-width: 761px) {
    max-width: 66%;
  }
`;
export const ProfileTitle = styled.h1`
  margin-bottom: 31px;
  align-self: flex-start;
  @media only screen and (max-width: 760px) {
    font-size: 24px;
  }
`;
export const ProfileForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const ProfileFormInputs = styled.div`
  width: 100%;
  display: flex;
  gap: 51px;
  margin-bottom: 15px;
  @media only screen and (max-width: 760px) {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
`;

export const Wrapper = styled.div`
  margin-top: 18px;
  margin-bottom: 18px;
`;

export const Title = styled(Label)`
  color: white;
  font-size: 20px;
`;
