import React, {FC, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router';
import styled from 'styled-components';

import {useAppSelector} from '../../hooks/redux';
import $api from '../../http';
import {ITournaments} from '../../interface';
import {actionChangeListTournament} from '../../redux/reducer/listTournamentReducer';
import {cleanTournament} from '../../redux/reducer/tableTournamentReducer';
import {ItemTournament} from './ItemTournament/ItemTournament';

const Wrapper = styled.div`
  overflow-y: auto;
  align-self: flex-start;
  @media only screen and (max-width: 760px) {
    width: 100%;
  }
`;

const NoTournament = styled.div`
  margin-top: 100px;
  font-family: SubsoccerFont;
  font-style: normal;
  font-weight: normal;
  font-size: 50px;
  line-height: 60px;
  text-transform: uppercase;
  text-align: center;
`;

const ListTournamentHeader = styled.ul`
  display: flex;
  justify-content: space-between;
  list-style: none;
  right: 0;
  left: 0;
  padding: 10px 20px;
  top: 120px;

  li {
    font-size: 24px;
    line-height: 25px;
    letter-spacing: 0.05em;
    color: rgba(255, 255, 255, 0.8);

    &.active {
      color: white;
      padding-bottom: 5px;
      border-bottom: 2px solid #edede8;
    }
  }
`;

const ListTournamentStatus = styled.div`
    align-self: flex-start;
  font-weight: bold;
  font-size: 50px;
  line-height: 54px;
  letter-spacing: 0.006em;
  color: #ffffff;
  text-transform: uppercase;
  position: fixed;
  bottom: 15px;
  right: 15px;
  @media only screen and (max-width: 760px) {
    display: none;
  }
`;
const DateTournaments = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 26px;
  letter-spacing: 0.006em;
  color: #ffffff;
  margin-top: 64px;
`;
export const ListTournament: FC = () => {
  const [data, setData] = useState<any>();
  const [sortData, setSortData] = useState<ITournaments[]>();
  const [loading, setLoading] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const [drafted, setDrafted] = useState(false);
  const [clientWidth, setClientWidth] = useState(window.screen.width);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener('resize', () =>
      setClientWidth(window.screen.width),
    );
    return () => {
      window.removeEventListener('resize', () =>
        setClientWidth(window.screen.width),
      );
    };
  }, [clientWidth]);
  useEffect(() => {
    $api
      .get('/tournaments')
      .then(({data}) => {
        setData(data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
    return () => {
      dispatch(cleanTournament());
    };
  }, [deleted, drafted]);

  const {status} = useAppSelector(({listTournament}) => listTournament);
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    setSortData(
      data?.filter(
        (item) =>
          (!item.drafts && +new Date(item.date!) > Date.now()) ||
          (!item.drafts &&
            +new Date(item.date!).getDate() ===
            +new Date(Date.now()).getDate()),
      ),
    );
    if (status === 'Upcoming') {
      setSortData(
        data?.filter(
          (item) =>
            (!item.started &&
              !item.drafts &&
              +new Date(item.date!) > Date.now()) ||
            (!item.started &&
              !item.drafts &&
              +new Date(item.date!).getDate() ===
              +new Date(Date.now()).getDate()),
        ),
      );
    }
    if (status === 'Ongoing') {
      setSortData(data?.filter((item) => item.started && !item.ended))
    }
    if (status === 'Finished') {
      setSortData(data?.filter((item) => item.ended))
    }
    if (status === 'Drafts') {
      setSortData(data?.filter((item) => item.drafts))
    }
  }, [status, data]);

  if (loading) {
    return <h1>Loading...</h1>;
  }
  return (
    <Wrapper>
      {clientWidth < 768 && (
        <ListTournamentHeader >
          <li
            onClick={() => dispatch(actionChangeListTournament('Upcoming'))}
            className={`${status === 'Upcoming' ? 'active' : ''}`}
          >
            Upcoming
          </li>
          <li
            onClick={() => dispatch(actionChangeListTournament('Ongoing'))}
            className={`${status === 'Ongoing' ? 'active' : ''}`}
          >
            Ongoing
          </li>
          <li
            onClick={() => dispatch(actionChangeListTournament('Finished'))}
            className={`${status === 'Finished' ? 'active' : ''}`}
          >
            Finished
          </li>
          <li
            onClick={() => dispatch(actionChangeListTournament('Drafts'))}
            className={`${status === 'Drafts' ? 'active' : ''}`}
          >
            Drafts
          </li>
        </ListTournamentHeader>
      )}
      <div style={{overflowY: 'scroll', height: '80vh', borderRadius: 10}}>
        {data && sortData ? (
          <div>
            {sortData.map((item, index, array) => {
              const d = new Date(item.date!);
              const month = monthNames[d.getMonth()];
              const year = d.getFullYear();
              const currentDate =
                d.getMonth() !==
                new Date(array[index - 1]?.date || '').getMonth();
              return (
                <div key={item.creator! + index}>
                  {currentDate && (
                    <DateTournaments
                      style={{marginTop: index === 0 ? 0 : 64}}
                    >
                      {month} {year}
                    </DateTournaments>
                  )}
                  <ItemTournament
                    _id={item._id!}
                    date={item.date!}
                    status={status}
                    onClick={() => navigate(`/tournament/${item._id}`)}
                    name={item.name!}
                    deleted={deleted}
                    drafted={drafted}
                    setDeleted={setDeleted}
                    setDrafted={setDrafted}
                    rules={item.rules}
                    place={item.place}
                  />
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      {sortData && sortData.length < 1 && (
        <NoTournament>No tournaments found</NoTournament>
      )}
      <ListTournamentStatus>{status}</ListTournamentStatus>
    </Wrapper>
  );
};
