import {Flex} from '../../style/Custom';
import {
Input, Label, Option, StyledSelect,
} from './CreateTournamentForm.styled';

interface EloData {
  handleChangeOptions: (e) => void;
  kFactor: number;
}

export const EloTournamentForm: React.FC<EloData> = ({
  handleChangeOptions,
  kFactor
}) => {
  const optionsForSelect = [2, 4, 6, 8, 10, 12, 16];

  return (
    <div style={{width: '209px'}}>
      <Flex flex_direction="column">
        <Label>Select count of players</Label>
        <StyledSelect onChange={handleChangeOptions} name="selectedOption">
          <Option value="">Select</Option>
          {optionsForSelect.map((option: number) => (
            <Option key={option} value={option}>
              {option}
            </Option>
          ))}
        </StyledSelect>
      </Flex>
      <Flex flex_direction="column" style={{marginTop: 15}}>
        <label htmlFor="">K-factor</label>
        <Input
          type="number"
          placeholder="32"
          name="kFactor"
          value={kFactor}
          onChange={handleChangeOptions}
        />
      </Flex>
    </div>
  );
};
