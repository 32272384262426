import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { tableTournamentActionFetch, updatePlayersAsync } from '../actions/tournament';

interface ITournamentState {
  _id: string;
  additional: string;
  code_link: string;
  creator: string;
  date: string;
  drafts: boolean;
  ended_at: null;
  name: string;
  participants: number;
  place: string | null;
  playtime: null;
  rules: string;
  started: boolean;
  teams: { name: string; color: string }[];
  players: {
    guest: boolean,
    id: null | string,
    name: string,
    playerId: string,
    selected: boolean,
    team: string,
  }[];
  isLoading: boolean;
  error: boolean;
  started_at: null;
  kFactor: number;
  brackets: any;
  ended: boolean;
}

const initialState: ITournamentState = {
  _id: '',
  additional: '',
  code_link: '',
  creator: '',
  date: '',
  drafts: false,
  ended_at: null,
  name: '',
  participants: 2,
  place: '',
  playtime: null,
  rules: '',
  started: false,
  teams: [],
  players: [],
  isLoading: false,
  error: false,
  started_at: null,
  kFactor: 32,
  brackets: {},
  ended: false,
};

const tableTournamentSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    cleanTournament: (state) => {
      state._id = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(tableTournamentActionFetch.pending.type, (state) => {
        state.isLoading = true;
      })
      .addCase(
        tableTournamentActionFetch.fulfilled.type,
        (state, { payload }: PayloadAction<ITournamentState>) => {
          const {
            _id,
            creator,
            teams,
            name,
            participants,
            additional,
            code_link,
            date,
            drafts,
            ended_at,
            place,
            rules,
            started,
            players,
            started_at,
            kFactor,
            brackets,
            ended
          } = payload;
          state._id = _id;
          state.teams = teams;
          state.creator = creator;
          state.name = name;
          state.additional = additional;
          state.code_link = code_link;
          state.date = date;
          state.drafts = drafts;
          state.ended_at = ended_at;
          state.place = place;
          state.rules = rules;
          state.started = started;
          state.players = players;
          state.started_at = started_at;
          state.isLoading = false;
          state.participants = participants;
          state.kFactor = kFactor,
          state.brackets = brackets,
          state.ended = ended
        }
      )
      .addCase(
        tableTournamentActionFetch.rejected.type,
        (state) => {
          state.error = true;
          state.isLoading = false;
        },
      )
      .addCase(updatePlayersAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePlayersAsync.fulfilled, (state, action) => {             
        state.players = action.payload.players;
        state.isLoading = false;
      })
      .addCase(updatePlayersAsync.rejected, (state:any, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export const { cleanTournament } = tableTournamentSlice.actions;
export default tableTournamentSlice.reducer;
