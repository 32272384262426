import $api from '../http';

export const addPlayersForTournament = async(reqData, id: string) => {
  try {
    const response = await $api.post(`/tournaments/${id}/player`, reqData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updatePlayersForTournament = async(
  reqData,
  id: string,
  playerId: string,
) => {
  try {
    const response = await $api.patch(
      `/tournaments/${id}/player/${playerId}`,
      reqData,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
