/* eslint space-before-function-paren: 0 */
import {  createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionGuestLogin, actionUserAuth, actionUserLogin, actionUserLogout } from '../actions/user';

enum UserRoles {
  User = 'User',
  Player = 'Player',
  Admin = 'Admin',
  MasterAdmin = 'MasterAdmin',
  Guest = 'Guest',
}

interface userState {
  user_id: string | null;
  user_email: string;
  name: string | null;
  nickname: string | null;
  phone: string | null;
  isLoading: boolean;
  error: string | [] | null;
  sharingId: string | null;
  roles: UserRoles | null,
  playedGames: number;
  wins: number;
  trophies: number;
  eloRank: number;
  dateOfBirth: number;
  country: string;
  homeAddress: string;
  instagram: string;
  tiktok: string;
  underageName: string;
  underageEmail: string;
  underagePhone: string;
  profileType: string;
  id_user: string;
  creator: string;
}

const initialState: userState = {
  user_id: null,
  user_email: '',
  name: null,
  nickname: null,
  phone: null,
  isLoading: false,
  error: null,
  sharingId: null,
  roles: null,
  playedGames: 0,
  wins: 0,
  trophies: 0,
  eloRank: 0,
  dateOfBirth: 0,
  country: '',
  homeAddress: '',
  instagram: '',
  tiktok: '',
  underageName: '',
  underageEmail: '',
  underagePhone: '',
  profileType: '',
  id_user: '',
  creator: ''
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: {
        //AUTH
        [actionUserAuth.pending.type]: (state) => {
            state.isLoading = true;
        },
        [actionUserAuth.fulfilled.type]: (
            state,
            {payload}: PayloadAction<any>,
        ) => {
            state.isLoading = false;
            state.user_email = payload.email;
            state.name = payload.name;
            state.phone = payload.phone;
            state.nickname = payload.nickname;
            state.sharingId = payload.sharingId;
            state.roles = payload.roles;
            state.creator = payload.creator;
            state.user_id = payload._id
        },
        [actionUserAuth.rejected.type]: (
            state,
            {payload}: PayloadAction<string>,
        ) => {
            state.isLoading = false;
        },
        //LOGIN
        [actionUserLogin.pending.type]: (state) => {
            state.isLoading = false;
        },
        [actionUserLogin.fulfilled.type]: (
            state,
            {payload}: PayloadAction<any>,
        ) => {                                  
            state.isLoading = false;
            state.user_email = payload.email;
            state.name = payload.name;
            state.phone = payload.phone;
            state.user_id = payload._id;
            state.nickname = payload.nickname;
            state.sharingId = payload.sharingId;
            state.roles = payload.roles;
        },
        //GUEST LOGIN
        [actionGuestLogin.pending.type]: (state) => {
          state.isLoading = true;
        },
        [actionGuestLogin.fulfilled.type]: (
            state,
            {payload}: PayloadAction<any>,
        ) => {          
            state.isLoading = false;
            state.user_email = payload;
            state.name = payload;
            state.phone = payload;
            state.user_id = payload;
            state.nickname = payload;
            state.sharingId = payload;
        },
        [actionGuestLogin.rejected.type]: (
            state,
            {payload}: PayloadAction<string>,
        ) => {
            state.isLoading = false;
            state.error = payload;
        },
        //LOGOUT
        [actionUserLogout.pending.type]: (state) => {
            state.isLoading = true;
        },
        [actionUserLogout.fulfilled.type]: (state) => {
            state.isLoading = false;
            state.user_id = null;
            state.user_email = '';
            state.name = null;
            state.nickname = null;
            state.phone = null;
            state.error = null;
            state.sharingId = null;
        },
        [actionUserLogout.rejected.type]: (
            state,
            {payload}: PayloadAction<string>,
        ) => {
            state.isLoading = false;
            state.error = payload;
        },

    },
});

export default userSlice.reducer;
