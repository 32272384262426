import { useEffect, useState } from 'react';
import { getSuitableEloTurnamentUsers } from '../../../services/tournament';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { updatePlayersAsync } from '../../../redux/actions/tournament';
import Select from 'react-select';

interface IPlayer {
  guest: boolean
  id: string
  name: string | null
  playerId: string
  selected: boolean
  team: string | null
  isAccepted: boolean
  isRequested: boolean
}

interface ISelectOptions {
  value: IPlayer
  label: string
}

const swapUsers = (oldId: string, newUser: IPlayer, playersList: any[]) => {
  return playersList.map(player => {
    if (player.id === oldId) {
      return newUser;
    }
    return player;
  })
}

const PlayerSelect: React.FC<{ defaultPlayer: IPlayer, tournamentId: string }> = ({ defaultPlayer, tournamentId }) => {
  const value = { value: defaultPlayer, label: defaultPlayer?.name || defaultPlayer?.id }
  const [options, setOptions] = useState<ISelectOptions[]>([])
  const { players: playersList } = useSelector(({ tableTournament }: RootState) => tableTournament);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    getSuitableEloTurnamentUsers("", tournamentId)
      .then((options) => setOptions(options));
  }, [])

  const handleChange = (inputValue) => {

    try {
      dispatch(updatePlayersAsync({ tournamentId, players: swapUsers(value.value.id, inputValue.value, playersList) }))
    } catch (error) {
      console.error("Failed to update players or fetch options", error);
    }

  }

  const handleInputChange = (inputValue) => {
    getSuitableEloTurnamentUsers(inputValue, tournamentId)
      .then((options) => setOptions(options));
  }

  return (
    <Select
      value={value}
      options={options}
      onChange={handleChange}
      onInputChange={handleInputChange}
    />
  )
}

export default PlayerSelect

