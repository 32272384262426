import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ArrowRightIcon from '@heroicons/react/24/outline/ArrowRightIcon'

const primaryPetrol = "#225f78"

const InputBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 28px;
  flex: 1;
  position: relative;
  padding: 0 5px;
  width: 100%;
  min-width: 25vh;
  height: 7vh;
  @media only screen and (max-width: 426px) {
  }
`;
const InputType = styled.input<InputTypeProps>`
  &[type='date'] {
    -webkit-appearance: none;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      filter: invert(100%);
    }
  }

  background: rgba(255, 255, 255, 0.0);
  border-radius: 50px;
  font-family: resolve;
  font-size: 20px;
  font-weight: 300;
  width:100%;
  height:100%;
  position:absolute;
  top:0px;
  left:0px;
  padding: 10px 35px;
  border: none;
  outline: none;
  color: #FFFFFF;
  border: 2px solid ${({ error, value }) => {
    if (error) {
      return '#CE482B'
    }
    if (value !== "") {
      return '#FFFFFF'
    }
    return primaryPetrol
  }};

  &::placeholder {
    font-family: resolve;
    font-size: 20px;
    color: ${primaryPetrol};
    letter-spacing: 0.05em;
    font-weight: 300;
    opacity: 0.9;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  @media only screen and (max-width: 426px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    &::-webkit-calendar-picker-indicator {
      -webkit-appearance: none;
    }
  }
`;
export const Label = styled.label`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.06em;
  margin-bottom: 5px;
  font-weight: 300;
  color: #383d42;
  @media only screen and (max-width: 425px) {
    font-size: 16px;
  }
`;
export const Error = styled.div<InputTypeProps>`
  position: absolute;
  font-weight: 400;
  bottom: -23px;
  right: 0;
  left: 0;
  text-align: center;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: 0.06em;
  color: #ce482b;
`;

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type: 'text' | 'number' | 'password' | 'date' | 'email' | 'tel';
  label?: string;
  placeholder: string;
  style?: any;
  inputStyle?: any;
  labelStyle?: any;
  value?: any;
  errorString?: string | [];
  min?: any;
  error?: string | false | undefined;
  valid?: boolean;
  onClick;
  icon: ReactNode
}

type InputTypeProps = {
  error?: boolean;
};

export const InputWithButton = ({
  errorString,
  min,
  label,
  placeholder,
  type,
  onChange,
  style,
  labelStyle,
  value,
  name,
  inputStyle,
  onClick,
  icon
}: InputProps) => {
  const buttonOpacity = value === "" ? 0 : 1
  return (
    <>
      <InputBox style={{ ...style }}>
        <Label style={{ ...labelStyle }}>{label}</Label>
        <InputType
          placeholder={placeholder}
          onChange={onChange}
          type={type}
          value={value}
          error={!!errorString}
          min={min}
          name={name}
          style={{ ... inputStyle}}
        />
        <button className='rounded-full h-10 w-10 z-10 bg-white mr-1 flex items-center justify-center transition-all' 
        style={{"opacity": buttonOpacity}} onClick={onClick}>
          {icon}
       </button>
       {!!errorString && <Error error={!!errorString}>{errorString}</Error>}
      </InputBox>
    </>
  );
};

//   return (
//     <div className="relative w-full flex items-center justify-end" style={{height: "50px"}}>
//     <input className=' absolute bg-transparent w-full h-full rounded-full p-2.5 text-2xl border-2 z-0 placeholder-white placeholder-opacity-75 transition-all' placeholder={placeholder} onChange={onChange} style={inputStyle}></input>
//     <button className='rounded-full h-10 w-10 relative z-1 bg-white mr-1 flex items-center justify-center transition-all' style={buttonStyle} onClick={handleClick}>
//       <ArrowRightIcon color="black" className="h-8 w-8"></ArrowRightIcon>
//       </button>
//     </div>
//   )
// }
