import {useState} from 'react';
import styled from 'styled-components';

import arrowDownSvg from '../../assets/svg/arrow_down.svg';

type DetailBoxProps = {
  open: boolean;
};
const DetailsBox = styled.div`
  position: absolute;
  bottom: -37%;
  display: flex;
  left: 55%;
  flex-direction: column;
  padding: 10px 30px 30px 20px;
  overflow: hidden;
  z-index: 9999;
  color: #383d42;
  ${({open}: DetailBoxProps) =>
    open
      ? `
    background-color:rgba(255, 255, 255, 0.3);
    border-radius: 8px;
    width: 50%;
    `
      : `
    `}
`;

const DetailsButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 10px;
  font-size: 1.5rem;
  padding: 5px 10px;
  border-radius: 4px;
  color: #fff;

  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 9px;
    background-image: url(${arrowDownSvg});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    margin-left: 20px;
  }

  ${({open}: DetailBoxProps) =>
    open
      ? `
    &::after {
        transform: rotate(180deg);
}
    `
      : `
    border: 2px solid white;

    `}
`;

const DetailsInfo = styled.div`
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;
  overflow: auto;
  font-family: 'ResolveSans';
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  color: #fff;

  ${({open}: DetailBoxProps) =>
    open &&
    `
    visibility: visible;
    opacity: 1;
    `}
`;

type DetailsProps = {
  details?: string | null;
  checkType: string;
};

export const Details = ({details, checkType}: DetailsProps) => {
  const [open, setOpen] = useState(false);

  return (
    <DetailsBox open={open}>
      <DetailsButton open={open} onClick={() => setOpen(!open)}>
        Rules
      </DetailsButton>
      <DetailsInfo
        open={open}
      >
        <p style={{marginBottom: 5}}>
          {checkType === 'Team' ? 'Team Rules' : 'Classic Rules'}
        </p>
        {checkType === 'Team' ? (
          <>
            <p>
              In a tournament there are two teams. Players participating in
              tournament are selected to represent a team.
            </p>
          </>
        ) : (
          <>
            <p>
              Game winner is player that wins two sets, each set is played to
              three points.
            </p>
            <p>Starting player is decided with Rock/Paper/Scissors.</p>
          </>
        )}
      </DetailsInfo>
    </DetailsBox>
  );
};
