import styled from 'styled-components'

type BurgerMenuType = {
  open: boolean;
};

type DropdownMenuType = {
  open: boolean;
}

export const BurgerWrapper = styled.div`
margin-left:auto;
  display: flex;
  align-items: center;
`

export const BurgerTitle = styled.span`
  font-size: 25.5px;
  line-height: 26px;
  text-transform: uppercase;
  margin-right: 20px;
`
export const BurgerMenuIcon = styled.div<BurgerMenuType>`
  width: 27px;
  height: 4px;
  background-color: ${({open}) => (open ? 'transparent' : 'white')};
  position: relative;
  z-index: 20;
  transition: all 0.1s ease-in-out;

  &::before {
    content: '';
    position: absolute;
    width: 27px;
    height: 4px;
    background-color: white;
    bottom: ${({open}) => (open ? '0px' : '-8px')};
    transform: rotate(${({open}) => (open ? '45deg' : '0')});
    transition: all 0.2s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    width: 27px;
    height: 4px;
    background-color: white;
    top: ${({open}) => (open ? '0px' : '-8px')};
    transform: rotate(${({open}) => (open ? '-45deg' : '0')});
    transition: all 0.2s ease-in-out;
  }
`
export const DropdownContainer = styled.li``;

export const DropdownList = styled.ul<DropdownMenuType>`
  max-height: ${({open}) => (open ? '1000px' : '0px')};
  list-style: none;
  overflow: hidden;
  padding: ${({open}) => (open ? '20px' : '0px')} 0;
  background-color: rgba(180, 0, 40);
  margin-top: ${({open}) => (open ? '20px' : '0px')};
  border-radius: 10px;
`

export const BurgerMenu = styled.ul`
  height:100vh;
  width:100%;
  display:flex;
  flex-direction:column;
  align-items:flex-start;
  visibility: hidden;
  position: absolute;
  top: 60px;
  right: 0;
  background: linear-gradient(to right, #002c3c, #021a22);
  box-shadow: -7px 9px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px 0px 0px 10px;
  list-style: none;
  padding: 20px 50px;
  overflow: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;

  li {
    text-align: center;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.05em;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      &.active {
        color: #EDEDE8;
      }
    }
  }

  &.active {
    visibility: visible;
    opacity: 1;
  }
`
