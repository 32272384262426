import styled from 'styled-components';

export const LinkWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 15px;
  font-size: 18px;
  padding: 5px;
`;

export const Link = styled.a`
  border: 1px solid;
  padding: 3px 5px;
  border-radius: 4px;
`;
export const Title = styled.p`
  text-align: center;
  font-size: 25px;
  margin-top: 80px;
`;
