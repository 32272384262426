import {Flex, Title} from '../../style/Custom';
import {BoxItem} from './CreateTournamentForm.styled';

interface Rules {
  checkType: string;
  active: string;
}

export const Rules = ({active, checkType}) => {
  return (
    <BoxItem>
      <Flex justify="center" style={{borderBottom: '1px solid red'}}>
        <Title style={{alignContent: 'center'}} active={active}>
          {checkType === 'Team' ? 'Team Rules' : 'Classic Rules'}
        </Title>
      </Flex>
      {checkType === 'Classic' || checkType === 'ELO' ? (
        <ul className='flex flex-col items-center'>
          <li>
            <h2 className='mb-3' style={{fontSize: "25px"}}>Game Start:</h2>
            <p>
          The game starts with the winner of a rock-paper-scissors mini-game.</p><br/>
          <p>The kickoff is taken from inside one's own goal area.</p><br/>
          </li>

          <li>
          <h2 className='mb-3' style={{fontSize: "25px"}}>Game Play:</h2>
          The game is played wearing socks.<br/><br/>
          Feet must remain on one's own side of the field at all times.<br/><br/>
          Knees must stay above the surface of the benches.<br/><br/>
          The buttocks must stay in contact with the bench.
          </li>

          <li>
          <h2 className='mb-3' style={{fontSize: "25px"}}>Game Winner:</h2>
          The first to score three goals wins the game.
          </li>
        </ul>
      ) : (
        <ul className='flex flex-col items-center'>
          <li>
            In a tournament there are two teams. Players participating in
            tournament are selected to represent a team.
          </li>
          <li>
            Each round players are drawn primarily to play against a member of
            opponent team and if there are not enough opponents to play against,
            then secondarily against their own team member.
          </li>
          <li>
            Participants for the next round will be drawn after all the games in
            current round has been played.
          </li>
        </ul>
      )}
    </BoxItem>
  );
};
