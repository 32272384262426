import styled from 'styled-components';

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ModalContent = styled.div`
  background: linear-gradient(
      97.46deg,
      #429abe -57.08%,
      rgba(66, 154, 190, 0) 57.19%
    ),
    #002a3c;
  padding: 25px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 300px;
  color: white;
`;

export const IconWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

export const ModalButton = styled.button`
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 8px;
  border: 1px solid white;
  cursor: pointer;
`;
export const ModalTitle = styled.p`
  text-align: center;
  width: 100%;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
`;
export const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 15px;
`;
export const StyledLabel = styled.label`
  display: flex;
  gap: 5px;
`;
