import React from "react";
import { lightPetrol } from "../../../assets/colors";
export const TournamentProgressBar = ({progress = "Tournament", stages = ["Tournament", "Players", "Bracket"]}) => {

    const styles = {}
    stages.forEach(stage => {
        styles[stage] = {borderBottom: `2px solid ${lightPetrol}`, color: "white"}
    })
    styles[progress].borderBottom = "2px solid red"

    return (
    <div className="flex justify-center items-center mb-5 mx-auto">
        <div style={styles[stages[0]]}><h2 className="px-5" style={{fontSize: "25px"}}>{stages[0]}</h2></div>
        <div style={styles[stages[1]]}><h2 className="px-5" style={{fontSize: "25px"}}>{stages[1]}</h2></div>
        <div style={styles[stages[2]]}><h2 className="px-5" style={{fontSize: "25px"}}>{stages[2]}</h2></div>
    </div>
    )
}
