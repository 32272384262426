import $api from '../http';

export const addPlayerRequest = async(
  id: string | undefined,
  reqData: { team?: string; name: string},
) => {
  try {
    const response = await $api.post(`/tournaments/${id}/player/request`, reqData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
