import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux'
import {isEqual} from 'lodash'

import {AppDispatch, RootState} from '../redux'
// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useShallowEqualSelector: TypedUseSelectorHook<RootState> = (selector) => {
  return useSelector(selector, isEqual)
}
