import React from 'react'
import { useState } from 'react'
import { QuickplayPointsCounter } from "./QuickplayPointsCounter"
import { useNavigate } from 'react-router-dom'
const primaryPetrol = "#225f78"
export const QuickPlay = ({}) => {
    const [playerOnePoints, setPlayerOnePoints] = useState(0)
    const [playerTwoPoints, setPlayerTwoPoints] = useState(0)
    const navigate = useNavigate()
    if (playerOnePoints >= 3 || playerTwoPoints >= 3) {
        navigate(`/quickplay-results?p1=${playerOnePoints}&p2=${playerTwoPoints}`)
    }
    return <div className='w-full h-full absolute left-0 top-0' style={{height: "100%", backgroundColor: "black", padding:0, margin:0}}>
        <QuickplayPointsCounter 
        points = {playerOnePoints} 
        setPoints={setPlayerOnePoints} 
        player="Player 1" 
        style={{height: "50%"}}/>
        <QuickplayPointsCounter
        points = {playerTwoPoints} 
        setPoints={setPlayerTwoPoints} 
        player="Player 2" 
        style={{height: "50%", borderTop: `2px solid ${primaryPetrol}`}}/>
    </div>
}
