import { useSearchParams } from "react-router-dom"
import { TrophyIcon } from "@heroicons/react/24/outline";
import { RoundedButton } from "../common/RoundedButton/roundedButton";
import { PlayIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

export const QuickPlayResults = ({}) => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const player1Points = parseInt(searchParams.get("p1")!)
    const player2Points = parseInt(searchParams.get("p2")!)

    return (
        <div className="flex flex-col">
            <div className="flex justify-center items-center mb-10">
            <h3 style={{fontSize: "30px"}}>{searchParams.get("p1")}</h3>
            <TrophyIcon className="h-10 w-10 mx-5"></TrophyIcon>
            <h3 style={{fontSize: "30px"}}>{searchParams.get("p2")}</h3>
            </div>

            <p className="text-center" style={{fontSize: "30px"}}>Winner:</p>
            <p className="text-center" style={{fontSize: "20px", marginBottom: "20px"}}>{player1Points > player2Points ? "Player 1" : "Player 2"}</p>

            <RoundedButton
                primary
                style={{
                    marginBottom: 9,
                    marginTop: 50,
                    backgroundColor: "#cc2b24",
                }}
                onClick={() =>
                    navigate("/quick-play")
                }
                text="New match"
            >
                <PlayIcon className="h-8 w-8 ml-2"></PlayIcon>
        </RoundedButton>
        </div>
    )
}
