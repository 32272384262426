import Select from 'react-select';
const primaryPetrol = "#225f78"
interface ISelect {
  options: {
    label: string;
    value: string;
    color?: string;
  }[];
  handleChange: (e) => void;
  searchValue?: string;
  handleInputChange?: (e) => void;
  label: string;
  placeholder: string;
}

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    color: "white",
    backgroundColor: "transparent",
    borderBottom: `1px solid ${primaryPetrol}`,
    boxShadow: "none"
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'blue' : 'black',
    backgroundColor: state.isFocused ? '#e3f2fd' : 'white',
    cursor: 'pointer'
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: 'white'
  })
};

const SelectForSearch = ({
  options,
  handleInputChange,
  searchValue,
  label,
  placeholder,
}: ISelect) => {
  const handleChange = (selectedOption) => {
    handleInputChange && handleInputChange(selectedOption.label);
  };
  const renderedLabel = label === "" ? <></> : <label>{label}</label>
  return (
    <>
    {renderedLabel}
      <Select
        components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
        onInputChange={handleInputChange}
        options={options}
        value={
          searchValue
            ? options.find((option) => option.label === searchValue)
            : null
        }
        onChange={handleChange}
        placeholder={placeholder}
        isClearable={false}
        isSearchable
        required
        styles={customStyles}
      />
    </>
  );
};

export default SelectForSearch;
