import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import { Input } from '../common/Input/Input';
import Portal from './Portal';
import { Button } from '../common/Button/Button';

// Hooks
import { useInput } from '../../hooks/useInput';
import { useAppSelector } from '../../hooks/redux';

// Styles
import {
  IconWrapper,
  ModalContent,
  ModalTitle,
  ModalWrapper,
  StyledLabel,
  Wrapper,
} from './styled';

// Assets
import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';

interface GuestAddingModal {
  setOpen: any;
  applyGuestHandlerClick: (guest, playerName, color) => void;
  type: 'Team' | 'Classic';
}

export const GuestAddingModal: React.FC<GuestAddingModal> = ({
  setOpen,
  applyGuestHandlerClick,
  type,
}) => {
  const { user_email, name } = useAppSelector(({ user }) => user);
  const guest = useInput('');
  const playerName = useInput(name ? name : user_email);
  const [selectedColor, setSelectedColor] = useState('');

  const closeModal = () => {
    setOpen(false);
  };

  const handleCheckboxChange = (color) => {
    setSelectedColor(color);
  };

  return (
    <Portal>
      <ModalWrapper>
        <ModalContent>
          <IconWrapper onClick={closeModal}>
            <CloseIcon style={{ width: '30px', height: '30px' }} />
          </IconWrapper>
          {!user_email ? (
            <>
              <ModalTitle>
                Send a join request <br />
                <span style={{ fontSize: '15px' }}>as a Guest</span>
              </ModalTitle>
              <Input
                {...guest}
                type="text"
                placeholder="Name"
                inputStyle={{
                  fontSize: '15px',
                  height: '35px',
                  maxWidth: '250px',
                }}
                labelStyle={{ color: 'white' }}
              />
            </>
          ) : (
            <>
              <ModalTitle>
                Send a join request <br />
                <span style={{ fontSize: '15px' }}>as a Player</span>
              </ModalTitle>
              <Input
                {...playerName}
                type="text"
                placeholder="Player Name"
                inputStyle={{
                  fontSize: '15px',
                  height: '35px',
                  maxWidth: '250px',
                }}
                labelStyle={{ color: 'white' }}
              />
            </>
          )}
          {type === 'Team' ? (
            <>
              <p>Choose the team color:</p>
              <Wrapper>
                <StyledLabel>
                  <input
                    type="checkbox"
                    checked={selectedColor === 'Red'}
                    onChange={() => handleCheckboxChange('Red')}
                  />
                  Red
                </StyledLabel>
                <StyledLabel>
                  <input
                    type="checkbox"
                    checked={selectedColor === 'Blue'}
                    onChange={() => handleCheckboxChange('Blue')}
                  />
                  Blue
                </StyledLabel>
              </Wrapper>
            </>
          ) : null}
          <Button
            style={{ fontSize: '18px', height: '35px', width: '100%' }}
            onClick={() =>
              applyGuestHandlerClick(guest, playerName, selectedColor)
            }
          >
            Save
          </Button>
        </ModalContent>
      </ModalWrapper>
    </Portal>
  );
};
