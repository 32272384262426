import styled from "styled-components";
import { primaryPetrol } from "../../assets/colors";

const Wrapper = styled.div`
  margin-bottom: 28px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  border-radius:50px;
  border: 2px solid ${primaryPetrol};
  flex: 1;
  position: relative;
  padding: 0 5px;
  width: 100%;
  min-width: 25vw;
  height: 7vh;
  font-family: resolve;
  font-size: 20px;
  font-weight: 300;
  @media only screen and (max-width: 426px) {
  }
  font-size: 20px;
  padding-right: 10px;
  p {
    padding: 10px 35px;
  }
`;
export const RoundedPlayerWrapper = ({children, style = {}}) => {
    return <Wrapper style={style}>{children}</Wrapper>
}
