import React from 'react'
import { PlusIcon, MinusIcon } from "@heroicons/react/24/solid"
import usePointHandler from '../TableTournament/usePointHanlder'

export const PlayerPointsCounter = ({points, setPoints, player, ...props}) => {

    return (
        <div className="mx-auto w-full flex justify-between items-center relative box-border p-3" {...props}>
        <h2 className="text-center ml-2" style={{fontSize: "25px"}}>{player}</h2>
        <div className="flex items-center justify-center">
            <div className='relative z-10' onClick={() => setPoints(Math.max(points - 1, 0))}><MinusIcon className="h-8 w-8"></MinusIcon></div>
            <h1 className="mx-3"style={{fontSize: "35px"}}>{points}</h1>
            <div className='relative z-10' onClick={() => setPoints(Math.min(points + 1, 3))}><PlusIcon className="h-8 w-8"></PlusIcon></div>
        </div>
        </div>
    )
}
