import React from "react";
import { ClockIcon, CalendarIcon, MapPinIcon } from "@heroicons/react/24/outline";
import { lightPetrol } from "../../assets/colors";
import { TournamentProgressBar } from "../common/TournamentProgressBar/TournamentProgressBar";
export const TournamentInfo = ({tournament}) => {
    const tournamentDate = new Date(tournament.date)
    const tournamentDay = tournamentDate.toLocaleDateString().replaceAll("/", ".")
    const tournamentTime = tournamentDate.getHours() + ":" + tournamentDate.getMinutes()
    const tournamentPlace = tournament.place.split(",")[0] + ", " + tournament.place.split(",").pop()

    return (
        <div className="flex flex-col justify-left items-center">
            <div>
            <p>Tournament id: <span className="ml-3" style={{fontWeight:"bold"}}>{tournament.code_link}</span></p>
            <h2 style={{fontSize:"35px"}}>{tournament.name}</h2>
            <div className="flex">
                <div className="flex">
                    <CalendarIcon className="h-5 w-5" style={{color: lightPetrol}}></CalendarIcon>
                    <p className="ml-2">{tournamentDay}</p>
                </div>

                <div className="flex ml-5">
                    <ClockIcon className="h-5 w-5" style={{color: lightPetrol}}></ClockIcon>
                    <p className="ml-2">{tournamentTime}</p>
                </div>

                <div className="flex ml-5">
                    <MapPinIcon className="h-5 w-5" style={{color: lightPetrol}}></MapPinIcon>
                    <p className="ml-2">{tournamentPlace}</p>
                </div>
            </div>
            <div className="flex mt-8">
                <div style={{borderBottom: `2px solid red`, color: "white"}}><h2 className="px-5" style={{fontSize: "25px"}}>bracket</h2></div>
                <div style={{borderBottom: `2px solid ${lightPetrol}`, color: "rgba(0, 0, 0, 0)"}}><h2 className="px-5" style={{fontSize: "25px"}}>players</h2></div>
                <div style={{borderBottom: `2px solid ${lightPetrol}`, color: "rgba(0, 0, 0, 0)"}}><h2 className="px-5" style={{fontSize: "25px"}}>tournament</h2></div>
            </div>
            </div>
        </div>
    )
}
