import { createAsyncThunk } from '@reduxjs/toolkit';

import $api from '../../http';

export const actionUserInfo = createAsyncThunk(
  'user/actionUserInfo',
  async(id: string | null, { rejectWithValue }) => {
    try {
      const { data } = await $api.get(`user/${id}`);
      return data;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const actionGuestLogin = createAsyncThunk(
  'user/actionGuestLogin',
  async(key: string, { rejectWithValue }) => {
    try {
      const { data } = await $api.post(`user/guest-login`, { key });
      localStorage.setItem('token', data.accessToken);
      return data.user;
    } catch (error: any) {
      localStorage.removeItem('token');
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const actionUserAuth = createAsyncThunk(
  'user/actionUserAuth',
  async(_, { rejectWithValue }) => {
    try {
      const { data } = await $api.get('user/refresh');
      localStorage.setItem('token', data.accessToken);
      return data.user;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const actionUserLogin = createAsyncThunk(
  'user/actionUserLogin',
  async(
    { user_email, password }: { user_email: string; password: string },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await $api.post('user/login', { user_email, password });
      localStorage.setItem('token', data.accessToken);
      return data.user;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const actionUserRegistration = createAsyncThunk(
  'user/actionUserRegistration',
  async(
    {
      user_email,
      password,
      subscribe,
      country
    }: { user_email: string; password: string; subscribe: boolean, country: string },
    { rejectWithValue },
  ) => {
    try {
      await $api.post('user/registration', {
        user_email,
        password,
        subscribe,
        country
      });
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

export const actionUserLogout = createAsyncThunk(
  'user/actionUserLogout',
  async(_, { rejectWithValue }) => {
    try {
      const { data } = await $api.post('user/logout');
      localStorage.removeItem('token');
      return data.user;
    } catch (error: any) {
      return rejectWithValue(error.response.data.message);
    }
  },
);
