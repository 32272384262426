import { FC, useState } from 'react';
import { TrophyIcon } from '@heroicons/react/24/solid';

import ScoreCounter from '../TableTournament/ScoreCounter';
// Components
// Styles
import { ConnectingLine, GroupTeam, StageBlock } from './styled';
import { PointCounter } from '../TableTournament/PointCounter';
import { primaryPetrol } from '../../assets/colors';
import { Dictionary } from '@reduxjs/toolkit';

interface PlayerProps {
  stage: number;
  width: number;
  lengthObject: {
    [key: string]: number;
  };
  brackets: {
    [groupKey: string]: GroupData;
  };
  handleActivePlayerChange: (cells: any, players: any) => void;
}

export const PlayerTournament: FC<PlayerProps> = ({
  brackets,
  stage,
  lengthObject,
  handleActivePlayerChange,
}) => {
  const isNotLastStage = stage !== Object.keys(lengthObject).length;
  const entries = Object.entries(brackets).sort((a: [string, GroupData], b: [string, GroupData]) => {
    if (a[1].opponent1.disabled || a[1].opponent2.disabled) {
      return 1
    }
    return -1
  })
  return (
    <StageBlock stage={stage}>
      {Object.entries(brackets).map(([groupKey, groupData], index) => {
        const indexTest =
          stage === 2 && lengthObject.column2 === 3
            ? index === 2
              ? 1
              : index
            : index;
        return (
          <GroupTeam
            onClick = {() => {
              handleActivePlayerChange(
                [groupData.opponent1.cellId, groupData.opponent2.cellId], 
                [groupData.opponent1.name, groupData.opponent2.name]
                )
            }}
            isAfterArrow={isNotLastStage}
            isBeforeArrow={stage !== 1}
            lengthObject={lengthObject}
            stage={stage}
            key={groupKey}
          >
            {isNotLastStage && <ConnectingLine index={indexTest} stage={stage} />}
            <ScoreCounter
              groupData={groupData}
              opponent={groupData.opponent1}
              key={groupData.opponent1.cellId}
              cellId={groupData.opponent1.cellId}
            />
            <div className='w-full' style={{height: "2px", backgroundColor: primaryPetrol}}></div>
            <ScoreCounter
              groupData={groupData}
              opponent={groupData.opponent2}
              key={groupData.opponent2.cellId}
              cellId={groupData.opponent2.cellId}
            />
            {(!isNotLastStage && brackets.match1.finishMatch) && 
            <div 
            className='absolute w-6 h-6 flex items-center justify-center'
            style={{right: "-12px", bottom:"27px", backgroundColor: primaryPetrol, zIndex: 200, borderRadius: "100%"}}>
              <TrophyIcon className='h-4 w-4 text-white'></TrophyIcon>
            </div>}
          </GroupTeam>
        );
      })}
    </StageBlock>
  );
};
