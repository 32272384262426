import React from "react"
import { NavLink } from "react-router-dom"
const menuButtonFontSize = "50px"

export const MenuButton = ({text, icon}) => {
    return (
        <div className="flex items-center justify-start">
        {icon}<p className='my-5 mx-5 text-left' style={{color: "white",fontSize: menuButtonFontSize, fontFamily: "Subsoccerfont"}}>{text}</p>
        </div>
    )
}
