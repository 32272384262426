import styled from 'styled-components';

interface IQRWrapper{
  mobile: boolean;
}

export const QRWrapper = styled.div<IQRWrapper>`
  position: fixed;
  z-index: 9999;
  left: ${({mobile}) => mobile ? "0%" : '50%'};
  top: ${({mobile}) => mobile ? "0%" : '50%'};
  transform: ${({mobile}) => mobile ? "none" : 'translate(-50%, -50%)'};
  width: ${({mobile}) => mobile ? "100%" : '50%'};
  height: 100%;
  background-color: #AEB1B6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
