import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { tableTournamentActionFetch } from '../../redux/actions/tournament'
import { setScoreForPlayer } from '../../services/tournament'
import { Flex } from '../../style/Custom'
import useClickOutside from './useCickOutside'

type PoinHandler = {
  isMobile: boolean;
}

const PoinHandlerContainer = styled.div<PoinHandler>`
  position: absolute;
  right: ${ ({ isMobile }) => isMobile ? '-57%' : '-45%' };;
  top: ${ ({ isMobile }) => isMobile ? '103%' : '50%' };
  z-index: 9999;
  transform: translateY(-50%);
  background: #88969d;
  border-radius: 3px;
  padding: 5px 5px;
  display: flex;
  flex-direction: column;
  width: 140px;
  gap: 5px;
`
export const PointHandlerBtn = styled.button`
  color: #fff;
  background: transparent;
  border: 1px solid #fff;
  width: 25px;
  height: 25px;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  border-radius: 2px;
  transition: transform 300ms ease;
  :hover {
    transform: scale(0.9);
  }
`
const Value = styled.span`
  background: #fff;
  color: #000;
  width: 25px;
  height: 25px;
  padding: auto;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 5px;
  border-radius: 2px;
`
const ActionButton = styled.button`
  width: 60px;
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  border-radius: 2px;
  cursor: pointer;
  transition: transform 300ms ease;
  :hover {
    transform: scale(0.9);
  }
`
const usePointHandler = (cellId, isMobile) => {
  const promiseRef = useRef<((value: unknown) => void) | null>(null)
  const { _id } = useAppSelector(({ tableTournament }) => tableTournament);
  const dispatch = useAppDispatch()

  const [value, setValue] = useState<number>(0)
  const [hidden, setHidden] = useState<boolean>(true)

  const handleIncrement = () => {
    setValue((prev) => {
      if (prev > 2) {
        return 3
      }
      return prev + 1
    })
  }
  const handleDecrement = () => {
    if (value >= 0) {
      setValue((prev) => {
        if (prev < 1) {
          return 0
        }
        return prev - 1
      })
    }
  }
  const handleReset = () => {
    if (value !== 0) {
      setValue(0)
    }
  }
  const handleConfirm = (cellId, score: number) => {
    setHidden(true)
    if (promiseRef.current) {
      promiseRef.current(value)
    }
    const reqData = {
      cellId: cellId,
      score: value
    }
    setScoreForPlayer(reqData, _id).then(() => {
      dispatch(tableTournamentActionFetch(_id!));
    })
  }

  const pointhandler = () => {
    return new Promise((resolve) => {
      promiseRef.current = resolve
    })
  }

  useEffect(() => {
    return () => {
      promiseRef.current = null
    }
  }, [])
  const handlePoints = async (score: number) => {
    setValue(score)
    setHidden(false)
    return await pointhandler()
  }

  const PointHandler = () => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    useClickOutside(wrapperRef, () => {
      setHidden(true)
    })

    if (hidden) {
      return null
    }
    return (
      <PoinHandlerContainer ref={ wrapperRef } isMobile={ isMobile }>
        <Flex justify='center' style={ { flex: 1 } }>
          <PointHandlerBtn disabled={ value === 0 } onClick={ handleDecrement }>
            -
          </PointHandlerBtn>
          <Value>{ value }</Value>
          <PointHandlerBtn onClick={ handleIncrement }>+</PointHandlerBtn>
        </Flex>
        <Flex justify='space-between' >
          <ActionButton disabled={ value === 0 } onClick={ handleReset }>
            reset
          </ActionButton>
          <span style={ { flex: 0.1 } } />
          <ActionButton onClick={() => {handleConfirm(cellId, value)}}>confirm</ActionButton>
        </Flex>
      </PoinHandlerContainer>
    )
  }
  return {
    handlePoints,
    PointHandler,
  }
}

export default usePointHandler;
