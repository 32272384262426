import React from 'react'
import { useRef } from 'react'
import { useState } from 'react'
import { PlayerPointsCounter } from "../Player/PlayerPointsCounter"
import { useAppDispatch, useAppSelector } from '../../hooks/redux'
import { setScoreForPlayer } from '../../services/tournament'
import { tableTournamentActionFetch } from '../../redux/actions/tournament'
import useClickOutside from './useCickOutside'

const primaryPetrol = "#225f78"

export const PointCounter = ({cells, players, setActiveCells}) => {
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [playerOnePoints, setPlayerOnePoints] = useState(0)
    const [playerTwoPoints, setPlayerTwoPoints] = useState(0)
    const promiseRef = useRef<((value: unknown) => void) | null>(null)
    const dispatch = useAppDispatch()
    const { _id } = useAppSelector(({ tableTournament }) => tableTournament);

    const reset = () => {
      setActiveCells(["", ""])
    }

    useClickOutside(wrapperRef, reset)

    const canConfirm = (playerOnePoints === 3 || playerTwoPoints === 3) && playerOnePoints !== playerTwoPoints

    const handleConfirm = (cellId, cellId2, score: number, score2:number) => {
        if (promiseRef.current) {
          promiseRef.current(score)
        }
        const reqData = {
          cellId: cellId,
          score: score
        }
        const reqData2 = {
          cellId: cellId2,
          score: score2
        }
        const order = score < score2 ? [reqData, reqData2] : [reqData2, reqData]
        setScoreForPlayer(order[0], _id).then(() => {
          setScoreForPlayer(order[1], _id).then(() => {
            dispatch(tableTournamentActionFetch(_id!));
          })
        })
      }

    const handlePointsConfirm = () => {
        handleConfirm(cells[0], cells[1], playerOnePoints, playerTwoPoints)
        setActiveCells(["", ""])
    }
    if (cells[0] === "") {
      return <></>
    }
    return <div className='bg-black fixed left-0 bottom-0 w-full z-50' ref={wrapperRef}>
        <PlayerPointsCounter 
        points = {playerOnePoints} 
        setPoints={setPlayerOnePoints} 
        player={players[0]} 
        style={{height: "50%"}}/>
        <PlayerPointsCounter
        points = {playerTwoPoints} 
        setPoints={setPlayerTwoPoints} 
        player={players[1]}
        style={{height: "50%", borderTop: `1px solid ${primaryPetrol}`}}/>
        {canConfirm ? 
        <button className='btn btn-primary w-full' style={{backgroundColor: primaryPetrol, height:"60px"}} onClick={handlePointsConfirm}>Confirm</button> : 
        <div style={{backgroundColor: primaryPetrol, height:"60px"}} ></div>}
    </div>
}
