import {FC, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router';
import { toast } from 'react-toastify';

import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import { actionGuestLogin } from '../../redux/actions/user';

export const GuestLogin: FC = () => {
    const dispatch = useAppDispatch();
    const {isLoading} = useAppSelector(({user}) => user);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const validFormats = ['kings', '<kings>', '%3Ckings%3E'];
        const currentKey = decodeURIComponent(location.search.substring(1));
        if (validFormats.includes(currentKey)) {
            dispatch(actionGuestLogin('kings'))
                .then(() => {
                    navigate('/create');
                })
                .catch(() => {
                    navigate('/login');
                });
        } else {
            toast.error('code not found', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
            navigate('/login');
        }
    }, [dispatch, location, navigate]);

    if (isLoading) {
        return <h1>Loading...</h1>;
    }
    return null;
};
