import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

// Components
import { PlayerTournament } from '../Player/PlayerTournament';
import UploadVideo from '../common/UploadVideoInput';
import { DownloadVideo } from '../DownloadVideo';
import { CodeLink } from '../ListInvitedPlayer/listInvitedPlayerStyled';
import VideoModal from '../Modal/VideoModal';
import { PlayAgain } from './PlayAgain';

// Hooks
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useClipboard from '../../hooks/useClipboard';

// Styles
import { Bottom, BottomShare, WrapperTournament } from './styled';
import { tableTournamentActionFetchSecond } from '../../redux/actions/tournament';
import QRCodeModal from '../QRCodeModal';
import { PointCounter } from './PointCounter';
import { TournamentProgressBar } from '../common/TournamentProgressBar/TournamentProgressBar';
import { TournamentInfo } from '../ListInvitedPlayer/TournamentInfo';

const TableWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 70px;
`;

export const TournamentTable = ({ showShare }) => {
  const { brackets, code_link, ended, _id, creator, place, name, date} = useAppSelector(
    ({ tableTournament }) => tableTournament,
  );
  const { user_email, user_id } = useAppSelector(({ user }) => user);

  const [clientWidth, setClientWidth] = useState(window.screen.width);
  const [widthPlayer, setWidthPlayer] = useState(280);
  const [dynamicBrackets, setDynamicBrackets] = useState(brackets);
  const { copied, copyToClipboard } = useClipboard();
  const [openQR, setOpenQR] = useState<boolean>(false)
  const [activeCells, setActiveCells] = useState(["", ""])
  const [activePlayers, setActivePlayers] = useState(["", ""])

  const handleActivePlayerChange = (cells, players) => {
    setActiveCells(cells)
    setActivePlayers(players)
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', () =>
      setClientWidth(window.screen.width),
    );
    window.addEventListener('orientationchange', () =>
      setClientWidth(window.screen.width),
    );
    return () => {
      window.removeEventListener('resize', () =>
        setClientWidth(window.screen.width),
      );
      window.removeEventListener('orientationchange', () =>
        setClientWidth(window.screen.width),
      );
    };
  }, [clientWidth]);

  useEffect(() => {
    if (clientWidth <= 1024) {
      setWidthPlayer(180);
    } else {
      setWidthPlayer(280);
    }
  }, [clientWidth]);

  const getTournamentLength = useMemo(() => {
    const obj = {};
    for (const value in dynamicBrackets) {
      obj[value] = Object.keys(brackets[value]).length;
    }
    return obj;
  }, [dynamicBrackets]);

  const copyHandlerClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    copyToClipboard(code_link);
  };
  const lastMatch = brackets[Object.keys(brackets).pop()!].match1
  let winner = ""
  if (lastMatch.finishMatch) {
    winner = lastMatch.opponent1.score > lastMatch.opponent2.score ? lastMatch.opponent1.name : lastMatch.opponent2.name
  }
  const tournamentData = {name, date, place, code_link}
  return (
    <>
    {!(user_id !== creator && user_id !== 'guest') ? <TournamentProgressBar progress='Bracket'></TournamentProgressBar> : <TournamentInfo tournament={tournamentData}></TournamentInfo>}

      <div style={{overflow: "scroll", maxWidth: "100vw"}}>
      <WrapperTournament>
        <TableWrapper>
          <QRCodeModal open={openQR} setOpen={setOpenQR}/>
          <PlayerTournament
            width={widthPlayer}
            brackets={dynamicBrackets?.column1}
            lengthObject={getTournamentLength}
            stage={1}
            handleActivePlayerChange={handleActivePlayerChange}
          />
          {brackets?.column2 ? (
            <PlayerTournament
              width={widthPlayer}
              brackets={dynamicBrackets?.column2}
              lengthObject={getTournamentLength}
              stage={2}
              handleActivePlayerChange={handleActivePlayerChange}
            />
          ) : null}
          {brackets?.column3 ? (
            <PlayerTournament
              width={widthPlayer}
              brackets={dynamicBrackets?.column3}
              lengthObject={getTournamentLength}
              stage={3}
              handleActivePlayerChange={handleActivePlayerChange}
            />
          ) : null}
          {brackets?.column4 ? (
            <PlayerTournament
              width={widthPlayer}
              brackets={dynamicBrackets?.column4}
              lengthObject={getTournamentLength}
              stage={4}
              handleActivePlayerChange={handleActivePlayerChange}
            />
          ) : null}
          {brackets?.column5 ? (
            <PlayerTournament
              width={widthPlayer}
              brackets={dynamicBrackets?.column5}
              lengthObject={getTournamentLength}
              stage={5}
              handleActivePlayerChange={handleActivePlayerChange}
            />
          ) : null}
          {brackets?.column6 ? (
            <PlayerTournament
              width={widthPlayer}
              brackets={dynamicBrackets?.column4}
              lengthObject={getTournamentLength}
              stage={6}
              handleActivePlayerChange={handleActivePlayerChange}
            />
          ) : null}
        </TableWrapper>
        <div>
          <div>
              <div style={{margin: "0 10px"}} >
                <CodeLink onClick={() => setOpenQR(true)}>
                  Tournament QR-code
                </CodeLink>
              </div>
              <CodeLink onClick={copyHandlerClick}>
              <div>Tournament ID:</div>
              <div>{copied ? 'Copied!' : `${code_link}`}</div>
            </CodeLink>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              alignItems: 'flex-end',
            }}
          >
          </div>
        </div>
      </WrapperTournament>
      {lastMatch.finishMatch ? <PlayAgain 
      winner={winner}></PlayAgain> : <></>}
      {/* <Bottom showShare={showShare}>
        {showShare && (
          <BottomShare>
            <p onClick={() => copyToClipboard(code_link)}>
              {copied ? 'Tournament Link Copied!' : 'Share Tournament'}
            </p>
          </BottomShare>
        )}
        <div>Tournament status: {ended ? 'Completed' : 'Started'}</div>
      </Bottom> */}
      <div style={{height: "200px"}}></div>
      {(user_id === creator || user_id === 'guest') && <PointCounter cells = {activeCells} players = {activePlayers} setActiveCells={setActiveCells}></PointCounter>}
      </div>
    </>
  );
};
