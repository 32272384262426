import React, {useRef, useState} from 'react';

// Assets
import {ReactComponent as CloseIcon} from '../../assets/svg/close.svg';
// Helpers
import useClickOutside from '../TableTournament/useCickOutside';
// Components
// Styles
import {QRWrapper} from './styled';
import QRCode from 'react-qr-code';

interface QRModal {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  open: boolean,
  mobile?: boolean
}

const QRModal: React.FC<QRModal> = ({setOpen, open, mobile}) => {
  const handleOpenClick = () => {
    setOpen(prev => !prev);
  };

  return (
    <>{open && 
      <QRWrapper mobile={!!mobile}>
        <CloseIcon onClick={handleOpenClick} style={{width: '30px', height: '30px', position: "absolute", right: "30px", top: "30px", cursor: "pointer"}}/>
        <QRCode
          size={window.innerHeight / 3}
          bgColor='transparent'
          value={window.location.href}
          style={{margin: "0 10px 10px"}}
          viewBox={`0 0 256 256`}
        />
      </QRWrapper>
      }
    </>
  );
};

export default QRModal;
