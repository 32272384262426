import React from 'react'

import {Account} from '../../components'

export const AccountPage = () => {
  return (
    <>
      <Account/>
    </>
  )
}
