import React, {
  FC,
  ReactNode,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';

import "react-toastify/dist/ReactToastify.css";
import './index.css';
import footerMobile from './assets/img/footer_mobile.png';
import {
  ActivateUser,
  ForgotPassword,
  GuestLogin,
  Header,
  Login,
  ResetPassword,
  QuickPlay,
  QuickPlayResults
} from './components';
import AllTournaments from './components/AllTournaments';
import { Registration } from './components/Auth/Registration';
import ErrorBoundary from './components/ErrorBoundary';
import { generateBackground } from './helpers/generateBackground';
import {
  useAppDispatch,
  useAppSelector,
  useShallowEqualSelector,
} from './hooks/redux';
import {
  AccountPage,
  CreateTournament,
  Main,
  PlayerPage,
  ProfilePage,
  EditProfilePage,
  Tournament,
  TournamentBracket,
  TableRegistrationPage
} from './Pages';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import Support from './Pages/Support';
import { actionUserAuth } from './redux/actions/user';
import { RulesPage } from './Pages/RulesPage/RulesPage';

const Wrapper = styled.div<{ loggedIn?: boolean; clientWidth: number }>`
  display: flex;
  flex-direction: column;
  color: white;
  overflow: ${({ clientWidth }) => (clientWidth < 768 ? 'scroll' : 'hidden')};
  background-image: linear-gradient(#002c3c, #021a22);
  background-size: cover;
  min-height: 100vh;
  @media only screen and (max-width: 768px) {
    background-repeat: no-repeat;
    background-position: 0 0px;
    background-size: cover;
  }
`;

const FooterMobile = styled.div<{ loggedIn?: boolean }>`
  height: 161px;
  width: 100%;
  background: url(${({ loggedIn }) => (loggedIn ? footerMobile : '')});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 1px;
  z-index: 900;
`;
const ContentInner = styled.div`
  padding: 0% 6%;
  overflow: scroll;
`;
const Content: FC<{ children: ReactNode }> = ({ children }) => (
  <ErrorBoundary>
    <ContentInner>{children}</ContentInner>
  </ErrorBoundary>
);

export const App = () => {
  const location = useLocation();

  const dispatch = useAppDispatch();
  const [clientWidth, setClientWidth] = useState(window.screen.width);
  const { user_email, isLoading } = useShallowEqualSelector(({ user }) => {
    return user;
  });   
  const RootRoute: React.FC = () => {
    const location = useLocation();

    // Check if there's a query string present
    if (location.search) {
      return <GuestLogin />;
    }
    return <Main />;
  };

  const { _id, started } = useAppSelector(
    ({ tableTournament }) => tableTournament,
  );

  useLayoutEffect(() => {
    window.addEventListener('resize', () =>
      setClientWidth(window.screen.width),
    );
    window.addEventListener('orientationchange', () =>
      setClientWidth(window.screen.width),
    );
    return () => {
      window.removeEventListener('resize', () =>
        setClientWidth(window.screen.width),
      );
      window.removeEventListener('orientationchange', () =>
        setClientWidth(window.screen.width),
      );
    };
  }, [clientWidth]);

  const firstLoad = useRef<boolean | null>(true);

  //this caused ?kings link to throw back to home screen
  // useEffect(() => {
  //   if (firstLoad.current) {
  //     firstLoad.current = false;
  //     dispatch(actionUserAuth());
  //   }
  //   return () => {
  //     firstLoad.current = null;
  //   };
  // }, [dispatch]);

  if (isLoading && firstLoad.current) {
    return (
      <Wrapper clientWidth={clientWidth}>
        <Content>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <h2>Loading...</h2>
          </div>
        </Content>
        <ToastContainer limit={1} />
      </Wrapper>
    );
  }
  if (!user_email) {
    return (
      <Wrapper clientWidth={clientWidth}>
        <Header />
        <TransitionGroup component={null}>
          <CSSTransition
            key={location.pathname}
            classNames="slide"
            timeout={1500}
          >
            <Content>
              <Routes>
                <Route path="/" element={<RootRoute />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/support" element={<Support />} />
                <Route path="/login" element={<Login />} />
                <Route path="/rules" element={<RulesPage/>}/>
                <Route path="/quick-play" element={<QuickPlay />} />
                <Route path="/quickplay-results" element={<QuickPlayResults />} />
                <Route path="/registration" element={<Registration />} />
                <Route path="/player-card/:id" element={<PlayerPage />} />
                <Route path="/tournament/:id" element={<TournamentBracket />} />
                {clientWidth >= 2560 && (
                  <Route path="/all" element={<AllTournaments />} />
                )}
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route
                  path="/reset-password/:userId"
                  element={<ResetPassword />}
                />
                <Route path="*" element={<Main />} />
              </Routes>
            </Content>
          </CSSTransition>
        </TransitionGroup>
        <ToastContainer limit={1} />
      </Wrapper>
    );
  }
  return (
    <Wrapper clientWidth={clientWidth} loggedIn={true}>
      <Header />
      <TransitionGroup component={null}>
        <CSSTransition
          key={location.pathname}
          classNames="slide"
          timeout={1500}
        >
          <Content>
            <Routes>
              <Route path="/" element={<Main />} />
              <Route path="/rules" element={<RulesPage/>}/>
              <Route path="/tournament/:id" element={<TournamentBracket />} />
              <Route path="/tournament" element={<Tournament />} />
              <Route path="/create" element={<CreateTournament />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/account" element={<AccountPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="profile/edit" element={<EditProfilePage></EditProfilePage>}/>
              <Route path="/player-card/:id" element={<PlayerPage />} />
              <Route path="tableregistration" element={<TableRegistrationPage></TableRegistrationPage>}/>
              {clientWidth >= 2560 && (
                <Route path="/tournament/all" element={<AllTournaments />} />
              )}
              <Route path="/activate/:_id" element={<ActivateUser />} />
              <Route path="*" element={<Main />} />
            </Routes>
          </Content>
        </CSSTransition>
      </TransitionGroup>
      <ToastContainer limit={1} />
    </Wrapper>
  );
};
