import styled from "styled-components";
import { primaryPetrol } from "../../../assets/colors";

const Wrapper = styled.div`
  margin-bottom: 28px;
  border-radius:50px;
  border: 2px solid ${primaryPetrol}
  flex: 1;
  position: relative;
  padding: 0 5px;
  width: max(25vw, 100%);
  height: 7vh;
  @media only screen and (max-width: 426px) {
  }
`;
export const RoundedWrapper = ({children}) => {
    return <Wrapper>{children}</Wrapper>
}
