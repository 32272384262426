import $api from '../http';
import { v4 as uuidv4 } from 'uuid';

interface IResponseUser {
  _id: string
  email: string
  firstName: string
  surname: string
  name: string
  nickname: string
  phone: string
  country: string | null
  password: string
  isActivated: boolean
  activationLink: string
  sharingId: string
  roles: string[]
  canLogin: boolean
  playedGames: number
  wins: number
  trophies: number
  eloRank: number
  profileType: string
  __v: number
}

export const createTournament = async(reqData) => {
  try {
    const response = await $api.post('/tournaments', reqData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getSuitableEloTurnamentUsers = async(search: string, tournamentId: string) => {
  try {
    const response = await $api.get(`/tournaments/users?search=${search}&tournamentId=${tournamentId}`);    
    return response.data.map((user: IResponseUser) => ({
      value: {
        guest: false,
        id: user.sharingId,
        name: user?.name,
        playerId: uuidv4(),
        selected: true,
        team: null,
        code_link: uuidv4(),
        isAccepted: true,
        isRequested: false,
      },
      label: user?.name || user?.sharingId,
    }));
  } catch (error) {
    throw error;
  }
};

export const getTournamentsById = async(id: string) => {
  try {
    const response = await $api.get(`/tournaments/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateTournamentUsers = async(tournamentId:string, players:any[]) => {
  try {
    const response = await $api.patch(`/tournaments/users`, {
      tournamentId,
      players
    });
    
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createLogoForTeam = async(reqData) => {
  try {
    const response = await $api.post('/tournaments/team/logo', reqData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateLogoForTeam = async(logoId) => {
  try {
    const response = await $api.patch(`/tournaments/team/logo/${logoId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteLogoForTeam = async(logoId) => {
  try {
    const response = await $api.delete(`/tournaments/team/logo/${logoId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getLogoById = async(logoId) => {
  try {
    const response = await $api.get(`/tournaments/team/logo/${logoId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getAllLogotypes = async() => {
  try {
    const response = await $api.get('/tournaments/team/logo');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const startTournamentsById = async(id: string, reqData) => {
  try {
    const response = await $api.patch(`/tournaments/${id}`, reqData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const setScoreForPlayer = async(reqData, id: string) => {
  try {
    const response = await $api.post(`/tournaments/${id}/score`, reqData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
