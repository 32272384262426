
import { TableService } from "../../services/tableRegistrationService"
import $api from '../../http'
export const TableRegistrationPage = ({}) => {
    const switchValues = {
        "on": true,
        "off": false
    }
    async function handleSubmit(e) {
        e.preventDefault()
        console.log(switchValues[e.target.public.value])
        await TableService.registerTable({
            serial_number: e.target.serial_number.value,
            visible: switchValues[e.target.visible.value],
            public: switchValues[e.target.public.value]
        })
        .then(response => console.log(response))
        .catch(err => console.log(err))

    }
    return (
        <form onSubmit={handleSubmit} style={{color:"black"}}>
            <input name="serial_number"></input>
            <input type="checkbox" name="visible"></input>
            <input type="checkbox" name="public"></input>
            <button>submit</button>
        </form>
    )
}
