import { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import { PlusIcon, ClockIcon, XMarkIcon, ArrowRightIcon, ChatBubbleOvalLeftEllipsisIcon, EnvelopeIcon, LinkIcon} from '@heroicons/react/24/outline';
import { RoundedPlayerWrapper } from './RoundedPlayerWrapper';
// Components

import { Button } from '../common/Button/Button';
import { QrModal, QuestClassicPlayers} from '../index';
import { InputWithButton, RoundedWrapper, TournamentProgressBar} from '../index';
import { GuestAddingModal } from '../Modal/GuestAddingModal';
import Snackbar, { SnackBarProps } from '../Snackbar';
import { NextButton } from '../CreateTournamentForm/CreateTournamentForm.styled';
// Hooks
import useClipboard from '../../hooks/useClipboard';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

// Services
import { addPlayerRequest } from '../../services/addGuestPlayer';
import { updatePlayersForTournament } from '../../services/playersService';

// Interface
import { ITournaments } from '../../interface';
// Styles
import { Flex } from '../../style/Custom';
import {
  AddNewPlayer,
  ButtonGuestAccepted,
  CodeLink,
  InputAddPlayer,
  ItemPlayer,
  ItemsPlayer,
  ListPlayer,
  ListPlayerInner,
  ListPlayerTableList,
  ListPlayerTableListInfo,
  ListPlayerTableListPair,
  ListPlayerTableListPlayer,
  ListPlayerWrapper,
  SelectedTitle,
  ShareBox,
  ShareBoxButton,
  ShareBoxButtons,
  ShareBoxInner,
  ShareBoxTitle,
  TitleGuest,
  Wrapper,
} from './listInvitedPlayerStyled';

// Assets
import shareLinkSvg from '../../assets/svg/shareLink.svg';
import shareMailSvg from '../../assets/svg/shareMail.svg';
import whatsappSvg from '../../assets/svg/whatsapp.svg';
import QRCodeModal from '../QRCodeModal';
import PlayerSelect from '../common/PlayerSelect/PlayerSelect';
import { BorderColor } from 'styled-icons/material';
import { primaryPetrol, lightPetrol } from '../../assets/colors';

interface ClassicPlayers {
  applyUserHandlerClick: () => void;
  selectPlayerHandlerClick: (index, item) => void;
  newPlayer: any;
  listPlayer: {
    guest: boolean;
    id: string;
    name: string;
    playerId: string;
    selected: boolean;
    team: string;
    isAccepted: boolean;
    isRequested: boolean;
  }[];
  startHandlerClick: () => void;
  copyHandlerClick: () => void;
  error: string;
  copyLink: boolean;
  selected: number;
  data: ITournaments;
}

export const ClassicPlayers: React.FC<ClassicPlayers> = ({
  listPlayer,
  startHandlerClick,
  copyHandlerClick,
  data,
  selected,
  copyLink,
  newPlayer,
  applyUserHandlerClick,
  selectPlayerHandlerClick,
  error,
}) => {
  const { _id, code_link, rules, creator, date, place, name } = useAppSelector(
    ({ tableTournament }) => tableTournament,
  );
  const { user_email, user_id } = useAppSelector(({ user }) => user);

  const { copied, copyToClipboard } = useClipboard();
  const [openModal, setOpenModal] = useState(false);
  const [isQrOpen, setIsQrOpen] = useState<boolean>(false);
  const [snackbarData, setSnackbarData] = useState<{
    type: SnackBarProps['type'];
    message: SnackBarProps['message'];
    hide: SnackBarProps['hide'];
  }>({
    message: '',
    type: 'success',
    hide: true,
  });

  const { id } = useParams();

  useEffect(() => {
    if (!user_email) {
      setOpenModal(true);
    }
  }, []);

  const hideSnackBar = () => {
    setSnackbarData({ message: '', type: 'success', hide: true });
  };

  const handleCopyClick = () => {
    copyToClipboard(code_link);
  };

  const applyGuestHandlerClick = (guest, playerName) => {
    const name = guest.value ? guest : playerName;
    if (!name.value) {
      setSnackbarData({
        message: 'Please write your name or the name of the player',
        type: 'error',
        hide: false,
      });
      return;
    }
    const reqData = {
      name: name.value,
    };
    if (guest.value && playerName.value) {
      setSnackbarData({
        message: 'You can only choose one option',
        type: 'error',
        hide: false,
      });
      return;
    }
    addPlayerRequest(id, reqData)
      .then(() => {
        setOpenModal(false);
        setSnackbarData({
          message: 'Your application is requested!',
          type: 'success',
          hide: false,
        });
      })
      .catch(({ response }) => {
        setSnackbarData({
          message: response.data.message,
          type: 'error',
          hide: false,
        });
      });
  };

  const handleAcceptGuest = (playerId: string) => {
    const reqData = {
      selected: false,
      accepted: true,
    };
    updatePlayersForTournament(reqData, _id, playerId);
  };
  const tournamentData = {name, date, place, code_link}
  if (user_id !== creator && user_id !== 'guest') {
  return <QuestClassicPlayers open={openModal} setOpenModal={setOpenModal} applyGuestHandlerClick={applyGuestHandlerClick} tournament={tournamentData}></QuestClassicPlayers>
  }
  return (
    <ListPlayerWrapper>
      <QrModal
            qr={<QRCode
              size={150}
              value={window.location.href}
              bgColor="white"
            />}
            id={code_link}
            active={isQrOpen}
            setActive={setIsQrOpen}
            copied={copied}
            >
              <a
                href={`whatsapp://send?text=Invited you to Game! subsoccer.app/tournament/${_id}`}
              ><ChatBubbleOvalLeftEllipsisIcon className='h-8 w-8 text-white m-3'></ChatBubbleOvalLeftEllipsisIcon>
              </a>
              <a
                href={`mailto:?subject= Game Invite&body=Invited you to play with me! subsoccer.app/tournament/${_id}.`}
              >
                <EnvelopeIcon className='h-8 w-8 text-white m-3'></EnvelopeIcon>
              </a>

              <button onClick={handleCopyClick}>
              <LinkIcon className='h-8 w-8 text-white m-3'></LinkIcon>
              </button>
            </QrModal>
      <ListPlayerTableList>
      <TournamentProgressBar progress='Players'></TournamentProgressBar>
        <ListPlayerTableListInfo>Add players</ListPlayerTableListInfo>
        <InputWithButton 
            {...newPlayer} 
            placeholder='Player name' 
            icon={<PlusIcon color="black" className="h-8 w-8"></PlusIcon>}
            onClick={applyUserHandlerClick}/>
        <ListPlayerTableListPair>
          {listPlayer.map((player, indexPlayer) => {
            if (player.selected && player.isAccepted && rules !== 'ELO') {
              return (
                <ListPlayerTableListPlayer team={player.team} key={indexPlayer}>
                  <div onClick={() => selectPlayerHandlerClick(indexPlayer, player)}>
                  <RoundedPlayerWrapper
                    style={{color: "white", borderColor: "white"}}>
                      <p>{player.name}</p>
                      <XMarkIcon className='h-6 w-6' style={{color: primaryPetrol}}></XMarkIcon>
                    </RoundedPlayerWrapper>
                    </div>
                </ListPlayerTableListPlayer>
              );
            }
            if (rules === 'ELO') {
              return (
                <ListPlayerTableListPlayer team={player.team} key={indexPlayer}>
                  <PlayerSelect defaultPlayer={player} tournamentId={_id} />
                </ListPlayerTableListPlayer>
              );
            }
          })}
        </ListPlayerTableListPair>
      </ListPlayerTableList>
      <ListPlayer>
        <CodeLink onClick={copyHandlerClick}>
          Tournament ID {copyLink ? <p>Copied!</p> : <p>{code_link}</p>}
        </CodeLink>
        {(rules === 'Classic' && user_id === creator) ||
          user_email === 'guest' ? (
          <AddNewPlayer>
            {/* <InputAddPlayer
              {...newPlayer}
              type="text"
              placeholder="New Guest Player"
            />
            <Button
              onClick={applyUserHandlerClick}
              primary
              disabled={data.rules !== 'Classic'}
              style={{
                fontSize: 18,
                marginBottom: 10,
                width: '30%',
                height: '84%',
                backgroundColor: '#0085FF',
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            >
              ADD
            </Button> */}
          </AddNewPlayer>
        ) : (
          <Button
            onClick={() => setOpenModal(true)}
            primary
            disabled={data.rules !== 'Classic'}
            style={{
              fontSize: 18,
              marginBottom: 10,
              width: '30%',
              backgroundColor: '#0085FF',
            }}
          >
            JOIN
          </Button>
        )}
        {error && <span style={{ color: '#CE482B' }}>{error}</span>}
        {rules !== 'ELO' ? (
          <>
            <ListPlayerTableListInfo style={{ margin: '25px 0' }}>
              Accept players
            </ListPlayerTableListInfo>
            <ListPlayerInner>
              <ItemsPlayer>
                {listPlayer?.map((item, index) => (
                  <div
                    key={item.playerId + index}
                    onClick={() => selectPlayerHandlerClick(index, item)}
                  >
                    {(!item.isAccepted && creator === user_id) ||
                      (!item.isAccepted && 'guest' === user_id) ? (
                        <div onClick={() => handleAcceptGuest(item.playerId)}>
                        <RoundedPlayerWrapper
                      style={{color: "#ECECE7", borderColor: primaryPetrol}}>
                        <p>{item.name}</p>
                        <ClockIcon className='h-6 w-6' style={{color: lightPetrol}}></ClockIcon>
                      </RoundedPlayerWrapper>
                      </div>
                    ) : (
                      <></>
                      // <RoundedPlayerWrapper
                      // style={{color: "#ECECE7", borderColor: primaryPetrol}}>
                      //   <p>{item.name}</p>
                      //   <ClockIcon className='h-6 w-6' style={{color: lightPetrol}}></ClockIcon>
                      // </RoundedPlayerWrapper>
                    )}
                  </div>
                ))}
              </ItemsPlayer>
            </ListPlayerInner>
            <div className='flex flex-col items-center jusfity-center' style={{margin: "50px auto"}}>
              <button onClick={() => setIsQrOpen(true)}>
              <h2 className='mb-5' style={{fontSize:"25px"}}>Share game</h2>
            </button>
              <QRCode
                size={150}
                value={window.location.href}
                bgColor="white"
              />
            </div>
              <NextButton
          color={primaryPetrol}
          type="submit"
          onClick={startHandlerClick}
        >
          <p className='mx-auto'>Create bracket</p>
          <ArrowRightIcon className='h-5 w-5'></ArrowRightIcon>
        </NextButton>
            <Flex justify={'space-between'} align_items={'center'}>
              <SelectedTitle>Selected: {selected}</SelectedTitle>
            </Flex>
          </>
        ) : null}
      </ListPlayer>
      {openModal ? (
        <GuestAddingModal
          setOpen={setOpenModal}
          applyGuestHandlerClick={applyGuestHandlerClick}
          type="Classic"
        />
      ) : null}
      <Snackbar
        hide={snackbarData.hide}
        type={snackbarData.type}
        message={snackbarData.message}
        timeout={1000}
        callback={hideSnackBar}
      />
    </ListPlayerWrapper>
  );
};
