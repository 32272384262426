import { useEffect, useState } from 'react';
import { tableTournamentActionFetch } from '../../redux/actions/tournament';
import socket from '../../socket';

// Components
import { ClassicPlayers } from './ClassicPlayers';
import { WaitApproval } from './listInvitedPlayerStyled';
import { TeamPlayers } from './TeamPlayers';

// Hooks
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useInput } from '../../hooks/useInput';
import useClipboard from '../../hooks/useClipboard';

// Interface
import { ITournaments } from '../../interface';

// Services
import {
  addPlayersForTournament,
  updatePlayersForTournament,
} from '../../services/playersService';
import { startTournamentsById } from '../../services/tournament';

type ListInvitedPlayerProps = {
  data: ITournaments;
};

export const ListInvitedPlayer = ({ data }: ListInvitedPlayerProps) => {
  const { players, creator, isLoading, _id, code_link, started, rules, teams } =
    useAppSelector(({ tableTournament }) => tableTournament);
  const user = useAppSelector(({ user }) => user);
  const [selected, setSelected] = useState(0);
  const [listPlayer, setListPlayer] = useState<any[]>([]);
  const newPlayer = useInput('');
  const [playerInfo, setPlayerInfo] = useState({
    name: '',
    color: '',
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const dispatch = useAppDispatch();
  const filterSelected = listPlayer.filter((item) => item.selected);
  const [waiting, setWaiting] = useState(false);
  const { copied, copyToClipboard } = useClipboard();

  useEffect(() => {
    socket.emit('subscribeToTournament', _id);
    socket.on('onTournamentUpdated', (res) => {
      setListPlayer(res.players);
    });
    socket.on('new_update_tournament', (changedTournamentId: any) => {
      if (changedTournamentId.tournamentId == _id) {
        dispatch(tableTournamentActionFetch(_id!));
      }
    });
  }, []);

  const selectPlayerHandlerClick = (index: number, item) => {
    const copy = [...listPlayer];
    copy[index] = { ...copy[index], selected: !listPlayer[index].selected };
    setListPlayer(copy);
    if (creator === user.user_id || user.user_email === 'guest') {
      updatePlayersForTournament(
        { selected: !listPlayer[index].selected, accepted: !listPlayer[index].accepted},
        _id,
        item.playerId,
      ).then(() => {
        dispatch(tableTournamentActionFetch(_id!));
      });
    }
  };

  const addPlayerToTeam = (item) => {
    setPlayerInfo(item);
  };

  const startHandlerClick = () => {
    if (rules !== 'ELO' && filterSelected.length < 2) {
      setError('Need minimum 2 players');
    } else {
      startTournamentsById(_id, { started: true }).then(() => {
        socket.emit('tournament_started', { userId: user.user_id });
        dispatch(tableTournamentActionFetch(_id));
      });
    }
  };

  const applyUserHandlerClick = () => {
    const copy = [...listPlayer];
    if (copy.find((item) => item.name === newPlayer.value)) {
      setError('This user already exists!');
      return false;
    }
    if (newPlayer.value === '') {
      setError('The field cannot be empty!');
      return false;
    }
    copy.push({ guest: true, name: newPlayer.value });
    const reqData = {
      id: user.user_id,
      name: newPlayer.value,
      selected: true,
      ...(rules === 'Team' ? { team: playerInfo.color } : {}),
    };
    addPlayersForTournament(reqData, data._id)
      .then(() => {
        setError('');
        socket.emit('join_tournament', { tournamentId: data._id });
        setWaiting(true);
        dispatch(tableTournamentActionFetch(_id!));
      })
      .catch(({ response }) => {
        setSuccess('');
        setError(response.data.message);
      });
  };

  useEffect(() => {
    const filterSelected = listPlayer?.filter((item) => item.selected);
    setSelected(filterSelected?.length);
  }, [filterSelected?.length, listPlayer]);

  useEffect(() => {
    players && setListPlayer(players);
  }, [players]);

  const FindUser =
    !!players?.filter((player) => player.name === user.nickname)[0]?.name &&
    !started;

  const copyHandlerClick = () => {
    copyToClipboard(code_link);
  };

  if (user.user_id !== creator && (FindUser || waiting)) {
    return (
      <>
        <WaitApproval>
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </WaitApproval>
        <h2>Waiting for approval</h2>
      </>
    );
  }
  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (rules === 'ELO' || rules === 'Classic') {
    return (
      <ClassicPlayers
        listPlayer={listPlayer}
        startHandlerClick={startHandlerClick}
        copyHandlerClick={copyHandlerClick}
        data={data}
        selected={selected}
        copyLink={copied}
        newPlayer={newPlayer}
        applyUserHandlerClick={applyUserHandlerClick}
        selectPlayerHandlerClick={selectPlayerHandlerClick}
        error={error}
      />
    );
  }

  if (rules === 'Team') {
    return (
      <TeamPlayers
        applyUserHandlerClick={applyUserHandlerClick}
        selectPlayerHandlerClick={selectPlayerHandlerClick}
        teamsInfo={teams}
        newPlayer={newPlayer}
        listPlayer={listPlayer}
        code_link={code_link}
        id={_id}
        copyLink={copied}
        addPlayerToTeam={addPlayerToTeam}
        color={playerInfo.color}
        selected={selected}
        error={error}
        copyHandlerClick={copyHandlerClick}
        startHandlerClick={startHandlerClick}
      />
    );
  }
  return <div></div>;
};
