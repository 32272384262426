import { useEffect, useState } from 'react';

// Components
import { Button } from '../common/Button/Button';
import { TeamButton } from '../CreateTournamentForm/CreateTournamentForm.styled';
import { GuestAddingModal } from '../Modal/GuestAddingModal';

// Hooks
import useClipboard from '../../hooks/useClipboard';

// Services
import { updatePlayersForTournament } from '../../services/playersService';

// Styles
import {
  AddNewPlayer,
  ButtonGuestAccepted,
  CodeLink,
  InputAddPlayer,
  ItemPlayer,
  ItemsPlayer,
  ListPlayer,
  ListPlayerInner,
  ListPlayerTableList,
  ListPlayerTableListInfo,
  ListPlayerTableListPair,
  ListPlayerTableListPlayer,
  ListPlayerWrapper,
  SelectedTitle,
  ShareBox,
  ShareBoxButton,
  ShareBoxButtons,
  ShareBoxInner,
  ShareBoxTitle,
  TitleGuest,
  Wrapper,
} from './listInvitedPlayerStyled';
import { Flex } from '../../style/Custom';

// Assets
import shareLinkSvg from '../../assets/svg/shareLink.svg';
import shareMailSvg from '../../assets/svg/shareMail.svg';
import whatsappSvg from '../../assets/svg/whatsapp.svg';
import { useAppSelector } from '../../hooks/redux';
import { addPlayerRequest } from '../../services/addGuestPlayer';
import Snackbar, { SnackBarProps } from '../Snackbar';

interface TeamPlayers {
  applyUserHandlerClick: () => void;
  teamsInfo: {
    name: string;
    color: string;
  }[];
  newPlayer: any;
  listPlayer: {
    guest: boolean;
    id: string;
    name: string;
    playerId: string;
    selected: boolean;
    team: string;
    isAccepted: boolean;
    isRequested: boolean;
  }[];
  color: string;
  addPlayerToTeam: (team) => void;
  id: string;
  selected: number;
  selectPlayerHandlerClick: (index, item) => void;
  copyHandlerClick: (e) => void;
  error: string;
  copyLink: boolean;
  code_link: any;
  startHandlerClick: () => void;
}

export const TeamPlayers: React.FC<TeamPlayers> = ({
  applyUserHandlerClick,
  newPlayer,
  listPlayer,
  code_link,
  id,
  copyLink,
  addPlayerToTeam,
  color,
  selected,
  selectPlayerHandlerClick,
  copyHandlerClick,
  error,
  startHandlerClick,
}) => {
  const { copied, copyToClipboard } = useClipboard();
  const { user_email, user_id } = useAppSelector(({ user }) => user);
  const { creator, _id } = useAppSelector(
    ({ tableTournament }) => tableTournament,
  );

  const [openModal, setOpenModal] = useState(false);
  const [snackbarData, setSnackbarData] = useState<{
    type: SnackBarProps['type'];
    message: SnackBarProps['message'];
    hide: SnackBarProps['hide'];
  }>({
    message: '',
    type: 'success',
    hide: true,
  });

  useEffect(() => {
    if (!user_email) {
      setOpenModal(true);
    }
  }, []);

  const handleCopyClick = () => {
    copyToClipboard(code_link);
  };

  const applyGuestHandlerClick = (guest, playerName, color) => {
    const name = guest.value ? guest : playerName;
    if (!name.value) {
      setSnackbarData({
        message: 'Please write your name or the name of the player',
        type: 'error',
        hide: false,
      });
      return;
    }
    if (guest.value && playerName.value) {
      setSnackbarData({
        message: 'You can only choose one option',
        type: 'error',
        hide: false,
      });
      return;
    }
    const reqData = {
      name: name.value,
      team: color,
    };
    addPlayerRequest(id, reqData)
      .then(() => {
        setOpenModal(false);
        setSnackbarData({
          message: 'Your application is requested!',
          type: 'success',
          hide: false,
        });
      })
      .catch(({ response }) => {
        setSnackbarData({
          message: response.data.message,
          type: 'error',
          hide: false,
        });
      });
  };

  const hideSnackBar = () => {
    setSnackbarData({ message: '', type: 'success', hide: true });
  };

  const handleAcceptGuest = (playerId: string) => {
    const reqData = {
      selected: false,
      accepted: true,
    };
    updatePlayersForTournament(reqData, _id, playerId);
  };

  return (
    <ListPlayerWrapper>
      <ListPlayerTableList>
        <ListPlayerTableListInfo>Chosen Participants</ListPlayerTableListInfo>
        <ListPlayerTableListPair>
          {listPlayer?.map((player, indexPlayer) => {
            if (player.selected) {
              return (
                <ListPlayerTableListPlayer team={player.team} key={indexPlayer}>
                  {player.name || player.id}
                </ListPlayerTableListPlayer>
              );
            }
          })}
        </ListPlayerTableListPair>
      </ListPlayerTableList>
      <ShareBox>
        <ShareBoxInner>
          <ShareBoxTitle>Share tournament</ShareBoxTitle>
          <ShareBoxButtons>
            <ShareBoxButton>
              <a
                href={`whatsapp://send?text=Invited you to Game! subsoccer.app/tournament/${id}`}
              >
                <img src={whatsappSvg} alt="" />
                Whatsapp
              </a>
            </ShareBoxButton>
            <ShareBoxButton>
              <p onClick={handleCopyClick} style={{ cursor: 'pointer' }}>
                <img src={shareLinkSvg} alt="" />
                Share link
                {copied && <span>Link copied!</span>}
              </p>
            </ShareBoxButton>
            <ShareBoxButton>
              <a
                href={`mailto:?subject= Game Invite&body=Invited you to play with me! subsoccer.app/tournament/${id}.`}
              >
                <img src={shareMailSvg} alt="" />
                Share via mail
              </a>
            </ShareBoxButton>
          </ShareBoxButtons>
        </ShareBoxInner>
        <Button small onClick={startHandlerClick}>
          Start
        </Button>
      </ShareBox>
      <ListPlayer>
        <CodeLink onClick={copyHandlerClick}>
          Tournament ID
          {copyLink ? <p>Copied!</p> : <p>{code_link}</p>}
        </CodeLink>
        {creator === user_id ? (
          <>
            <AddNewPlayer>
              <InputAddPlayer
                {...newPlayer}
                type="text"
                placeholder="New Player"
              />
              <Button
                onClick={applyUserHandlerClick}
                primary
                style={{
                  fontSize: 18,
                  marginBottom: 10,
                  width: '30%',
                  height: '84%',
                  backgroundColor: '#0085FF',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                ADD
              </Button>
            </AddNewPlayer>
            <ListPlayerTableListInfo
              style={{
                fontSize: 20,
                margin: '5px auto',
                textAlign: 'center',
                width: 'fit-content',
                textTransform: 'inherit',
              }}
            >
              Add to
            </ListPlayerTableListInfo>
          </>
        ) : null}
        {error && <span style={{ color: '#CE482B' }}>{error}</span>}
        <Flex justify="space-between">
          <Flex
            justify="space-around"
            style={{ margin: '20px 0', width: '80%' }}
          >
            <TeamButton
              onClick={() =>
                addPlayerToTeam({
                  name: 'Team1',
                  color: 'Red',
                })
              }
              backgroundColor="red"
              active={color === 'Red'}
            >
              Team 1
            </TeamButton>
            <TeamButton
              onClick={() =>
                addPlayerToTeam({
                  name: 'Team2',
                  color: 'Blue',
                })
              }
              backgroundColor="blue"
              active={color === 'Blue'}
            >
              Team 2
            </TeamButton>
          </Flex>
        </Flex>
        <>
          <ListPlayerTableListInfo style={{ margin: '25px 0' }}>
            Applying
          </ListPlayerTableListInfo>
          <ListPlayerInner>
            <ItemsPlayer>
              {listPlayer.map((item, index) => (
                <div
                  key={item.playerId + index}
                  onClick={(e) => selectPlayerHandlerClick(index, item)}
                >
                  {!item.isAccepted && creator === user_id ? (
                    <Wrapper
                      selected={listPlayer[index].selected}
                      isAccepted={item.isAccepted}
                      key={item.name + index}
                    >
                      <p style={{ fontSize: '20px' }}>
                        <TitleGuest>New Guest:</TitleGuest>
                        {item.name}
                      </p>
                      <ButtonGuestAccepted
                        onClick={(e) => handleAcceptGuest(item.playerId)}
                      >
                        Accept a Guest
                      </ButtonGuestAccepted>
                    </Wrapper>
                  ) : (
                    <ItemPlayer
                      registered={!item.guest}
                      key={item.name + index}
                      selected={listPlayer[index].selected}
                    >
                      {item.name}
                    </ItemPlayer>
                  )}
                </div>
              ))}
            </ItemsPlayer>
          </ListPlayerInner>
          <Flex justify={'space-between'} align_items={'center'}>
            <SelectedTitle>Selected: {selected}</SelectedTitle>
          </Flex>
        </>
      </ListPlayer>
      {openModal ? (
        <GuestAddingModal
          setOpen={setOpenModal}
          applyGuestHandlerClick={applyGuestHandlerClick}
          type="Team"
        />
      ) : null}
      <Snackbar
        hide={snackbarData.hide}
        type={snackbarData.type}
        message={snackbarData.message}
        timeout={1000}
        callback={hideSnackBar}
      />
    </ListPlayerWrapper>
  );
};
